import { BiMinus, BiPlus } from 'react-icons/bi';

export const Stepper = ({
  name,
  value,
  min = 0,
  max = Infinity,
  invalid = false,
  disabled = false,
  onChange,
}) => {
  /**
   * @param {React.MouseEvent} e
   */
  function handleMinusClick(e) {
    e.stopPropagation();
    if (disabled || Number.isNaN(value) || value <= min) return;
    onChange({
      target: {
        name,
        value: Number(value) - 1,
      },
    });
  }

  /**
   * @param {React.MouseEvent} e
   */
  function handlePlusClick(e) {
    e.stopPropagation();
    if (disabled || Number.isNaN(value) || value >= max) return;
    onChange({
      target: {
        name,
        value: Number(value) + 1,
      },
    });
  }

  return (
    <div
      aria-required={invalid}
      className="flex w-[100px] select-none rounded-md border border-solid border-neutral-300 bg-white aria-required:border-error-900"
    >
      <span
        role="button"
        className="flex-1 py-1.5 text-center aria-disabled:cursor-default aria-disabled:bg-neutral-100"
        onClick={handleMinusClick}
        aria-disabled={disabled || value <= min}
      >
        <BiMinus className="text-small font-semibold" />
      </span>
      <div
        aria-disabled={disabled}
        className="w-9 shrink-0 py-1.5 aria-disabled:bg-neutral-100"
      >
        <span className="inline-block w-full border-0 border-x border-solid border-neutral-300 text-center text-heading-3 text-neutral-700">
          {value}
        </span>
      </div>
      <span
        role="button"
        className="flex-1 py-1.5 text-center aria-disabled:cursor-default aria-disabled:bg-neutral-100"
        onClick={handlePlusClick}
        aria-disabled={disabled || value >= max}
      >
        <BiPlus className="text-small font-semibold" />
      </span>
    </div>
  );
};
