import { mask, unMask } from 'remask';

export const inputFormatter = ({ event, maskString }) => {
  const value = event.target.value;
  let clearMask = unMask(value);
  event.target.value = mask(clearMask, [maskString]);
};

export const inputFormatterPercentage = ({ value, isDelete }) => {
  if (!value) return;

  let numericValue =
    typeof value === 'string' ? value.replace(/%/g, '') : value.toString();

  if (isDelete) {
    numericValue = numericValue.slice(0, -1);
  }

  if (numericValue.includes('.')) {
    const [integerPart, decimalPart] = numericValue.split('.');
    const formattedInteger = integerPart.slice(0, 2);
    const formattedDecimal = decimalPart.slice(0, 1);
    return `${formattedInteger}.${formattedDecimal}%`;
  }

  const maxLength = 2;
  if (numericValue.length <= maxLength) {
    return mask(numericValue, ['9%', '99%']);
  }

  return '100%';
};
