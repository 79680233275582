import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BreadCrumbs from '@components/breadcrumbs';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import api from '@services/api';
import { Suppliers } from '@system/enums';
import { toaster } from '@system/utils/toaster';
import PageLoading from '../components/PageLoading';
import { PagePath } from '../pages-config';
import ConferenciaReserva from './ConferenciaReserva';

const schema = yup.object().shape({
  code: yup.string().required('Campo obrigatório'),
  supplier: yup.string().required('Campo obrigatório'),
});

export default function ConferenciaReservasExternas() {
  const [reservationLoading, setReservationLoading] = useState(false);
  const [reservation, setReservation] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    try {
      setReservationLoading(true);
      const { supplier, code } = formData;
      setSelectedSupplier(supplier);

      const response = await api(`/reservations/${supplier}/${code}`);

      setReservation(response.data);
      setReservationLoading(false);
    } catch (err) {
      setReservationLoading(false);
      setReservation({});
      if (err.statusCode == 404) toaster('error', 'Reserva não encontrada.');
      else
        toaster(
          'error',
          'Problemas ao realizar a busca. Tente novamente mais tarde.',
        );
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Reservas', link: PagePath.Reservas },
            {
              label: 'Conferência de reservas',
              link: PagePath.ConferenciaReservaExterna,
            },
          ]}
        />
      </div>
      <div className="mb-5 mt-8 ">
        <h1 className="m-0 text-heading-1 text-primary">
          Conferência de reservas
        </h1>
      </div>
      <div className="my-0 rounded-md bg-white p-5 shadow-sm">
        <h2 className="pb-5 text-heading-2 text-primary">Buscar reserva</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-between flex flex-row">
            <div className="mr-2.5 flex w-full flex-col content-end justify-between gap-x-2.5 lg:flex-row">
              <Form.Group className="w-full">
                <Form.Label>Código da reserva</Form.Label>
                <InputV2
                  placeholder="Digite o código da reserva"
                  {...register('code', { required: true })}
                />
                <Form.Message error={errors.code} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Fornecedor</Form.Label>
                <SelectV2
                  options={Suppliers}
                  {...register('supplier', { required: true })}
                  placeholder="Selecione"
                />
                <Form.Message error={errors.supplier} />
              </Form.Group>
            </div>
            <ButtonV2
              className="mt-6 !max-h-14 !min-w-44"
              size="large"
              type="submit"
            >
              <ButtonV2.Text>Conferir reserva</ButtonV2.Text>
            </ButtonV2>
          </div>
        </Form>
      </div>
      {reservationLoading && (
        <div className="flex flex-col items-center gap-2 pt-7">
          <PageLoading />
          <span>Aguarde enquanto estamos buscando os resultados...</span>
        </div>
      )}

      {!reservationLoading && Object.keys(reservation).length !== 0 && (
        <ConferenciaReserva
          reservation={reservation}
          selectedSupplier={selectedSupplier}
        />
      )}
    </>
  );
}
