import { GoCheckCircle } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/button';
import { useData } from '@stores/checkout-context';
import { PaymentMethod } from '@system/enums';
import { Modal } from '.';
import { PagePath } from '../../pages-config';

export function ModalCheckoutConfirm({ modalData, onClose }) {
  const navigate = useNavigate();
  const { data } = useData();

  const handleClose = () => {
    onClose();
    navigate(
      PagePath.FinanceiroMultasReembolsosDetalhes.replace(
        ':id',
        data.referenceId,
      ),
      {
        replace: true,
      },
    );
  };

  return (
    <Modal open={Boolean(modalData)} size={'big'}>
      <div className="m-0 flex flex-col items-center gap-3 px-[150px] py-[30px]">
        <GoCheckCircle size={40} className="text-price" />
        <span className="m-0 text-center text-heading-1 text-price">
          Pagamento do cancelamento da reserva{' '}
          <b className="font-bold">{modalData}</b> confirmado!
        </span>
        <p className="m-0 mb-[30px] mt-[15px] w-2/3 text-center">
          {data.paymentMethod === 'credit-card'
            ? 'Você irá receber um e-mail com todas as informações da reserva, e o cliente irá receber um e-mail com o link para pagamento via cartão de crédito.'
            : 'Você irá receber um e-mail com todas as informações da reserva.'}
        </p>
        <Button onClick={handleClose} variant="ghost" label="Fechar" />
      </div>
    </Modal>
  );
}
