import BreadCrumbs from '@components/breadcrumbs';
import ReservationsForm from '@components/modules/aereo/ReservationForm';
import { Container } from '@layout';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from '../pages-config';

export default function AereoReservas() {
  const { session } = useAuthentication();
  const pageTitle = session?.agency?.nomeFantasia ?? 'Buscar reserva';

  return (
    <>
      <BreadCrumbs
        paths={[
          { label: 'Aéreo', link: PagePath.Aereo },
          { label: 'Reservas', link: PagePath.AereoReservas },
        ]}
      />
      <Container className="mt-5">
        <h2 className="m-0 text-heading-2 text-primary">{pageTitle}</h2>
      </Container>
      <ReservationsForm />
    </>
  );
}
