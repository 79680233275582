import './PageLoading.scss';

const PageLoading = () => {
    return (
        <div className="loading">
          <div className="loading2">
            <svg className="circular-loading" viewBox="25 25 50 50">
              <circle
                className="loading-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#E41047"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
    );
  };
  export default PageLoading;
  