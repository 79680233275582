import { createContext, useContext } from 'react';
import './tabs.scss';

const TabContext = createContext();

export const Tab = ({ children, value, onChange }) => (
  <TabContext.Provider value={{ value, onChange }}>
    {children}
  </TabContext.Provider>
);

// eslint-disable-next-line react/display-name
Tab.Heads = ({ children }) => (
  <div className="item-tabs-container m-0 flex !h-max list-none p-0">
    {children}
  </div>
);

// eslint-disable-next-line react/display-name
Tab.Item = ({ label, index }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { value, onChange } = useContext(TabContext);

  const handleClick = () => {
    onChange(index);
  };

  return (
    <div
      onClick={handleClick}
      className={`max-h-[52px] cursor-pointer px-3 py-0 focus:outline-none ${index === value ? 'text-secondary' : 'text-neutral-600'} m-0`}
    >
      <span
        className={`flex items-center justify-center ${index === value ? 'item-tabs-active' : 'item-tabs'} m-0 px-[23.5px] pb-[9px] pt-[17px] text-center text-[14px] text-heading-3`}
      >
        {label}
      </span>
    </div>
  );
};

// eslint-disable-next-line react/display-name
Tab.ContentWrapper = ({ children, className = '' }) => (
  <div className={`p-4 ${className}`}>{children}</div>
);

// eslint-disable-next-line react/display-name
Tab.Content = ({ children, index }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { value } = useContext(TabContext);
  return value === index ? <div>{children}</div> : null;
};
