import { twMerge } from 'tailwind-merge';

export function Container({ children, className }) {
  return (
    <div
      className={twMerge(
        'rounded-md border border-solid border-neutral-300 bg-white p-5',
        className,
      )}
    >
      {children}
    </div>
  );
}
