import { useState } from 'react';
import { CgLogOut } from 'react-icons/cg';
import { HiMenu, HiX } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';

import { PagePath } from '@/pages-config';
import Logo from '@components/Logo';
import { useAuthentication } from '@stores/authentication';

const styles = {
  menu: tv({
    base: 'cursor-pointer text-2xl md:hidden',
    variants: {
      open: {
        true: 'block',
        false: 'hidden',
      },
    },
  }),
  profile: tv({
    base: 'absolute right-0 top-14 z-10 w-52 rounded bg-white text-sm shadow-md',
    variants: {
      show: {
        true: 'block',
        false: 'hidden',
      },
    },
  }),
};

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { session, methods } = useAuthentication();

  const navigate = useNavigate();

  function handleOpenMenu() {
    if (
      document.getElementById('sidebar').style.display == 'none' ||
      document.getElementById('sidebar').style.display == ''
    ) {
      document.getElementById('sidebar').style.display = 'block';
      setOpenMenu(true);
    } else {
      document.getElementById('sidebar').style.display = '';
      setOpenMenu(false);
    }
  }

  function handleLogOut() {
    setShowUserMenu(false);
    methods.signOut();
    navigate('/', {
      state: {
        from: window.location.href,
      },
    });
  }

  function handleMyProfile() {
    setShowUserMenu(false);
    navigate(PagePath.MeusDados);
  }

  function profileFullName(data) {
    if (!data) return '';
    return `${data.firstName} ${data.familyName}`;
  }

  return (
    <header className="w-full bg-white">
      <div className="flex w-full justify-between p-2.5">
        <Link to={PagePath.Home}>
          <div className="max-w-[170px]">
            <Logo />
          </div>
        </Link>
        <div
          className="cursor-pointer"
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <span className="hidden align-middle text-sm md:inline-block">
            {profileFullName(session)}
          </span>{' '}
          <HiMenu
            id="hamburger"
            onClick={handleOpenMenu}
            className={styles.menu({ open: !openMenu })}
          />
          <HiX
            id="hix"
            onClick={handleOpenMenu}
            className={styles.menu({ open: openMenu })}
          />
        </div>
        <div className={styles.profile({ show: showUserMenu })}>
          <div
            className="cursor-pointer border-0 border-b border-solid border-gray-200 p-3"
            onClick={handleMyProfile}
          >
            Meus Dados
          </div>
          <div className="cursor-pointer p-3" onClick={handleLogOut}>
            <CgLogOut /> Logout
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
