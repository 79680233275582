import { useEffect, useState } from 'react';
import { BiSolidChevronRight } from 'react-icons/bi';
import { MdOutlineHome } from 'react-icons/md';
import Iframe from 'react-iframe';
import { Link, useSearchParams } from 'react-router-dom';
import * as jose from 'jose';

import LoadingAnimation from '@components/LoadingAnimation.jsx';
import { REPORTS_TYPES } from '@pages/Relatorios.jsx';
import { PagePath } from '../pages-config.js';

const configMetabase = {
  url: 'https://book2b.metabaseapp.com',
  secret: '6890a03624e2188279aaa4f7e70a95ea2110b548a3df95e9d0c47f7754ecdd21',
};

function RelatorioVisualizacao() {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();
  const [searchParams] = useSearchParams();

  const report = Object.fromEntries(searchParams);
  const { name, id: reportId, type, ...params } = report;
  const title = decodeURI(name);

  function getHeightByName(name) {
    for (const key in REPORTS_TYPES) {
      if (REPORTS_TYPES[key].name === name) {
        return REPORTS_TYPES[key].height;
      }
    }
  }

  async function generateToken() {
    const secret = new TextEncoder().encode(configMetabase.secret);
    const alg = 'HS256';
    const payload = {
      resource: {
        [type]: Number(reportId),
      },
      params: params || {},
    };

    let generatedToken = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg })
      .setExpirationTime('2h')
      .sign(secret);

    setToken(generatedToken);
    setLoading(false);
  }

  useEffect(() => {
    generateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const url = `${configMetabase.url}/embed/${type}/${token}#theme=transparent&bordered=false&titled=false`;
  const reportEmbed = token ? url : null;

  const heightIframe = getHeightByName(title);

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="breadCrumb mx-auto my-0">
        <MdOutlineHome className="icoBread" />
        <BiSolidChevronRight className="caretRight" /> &nbsp;&nbsp;
        <Link to={PagePath.Relatorios} relative="path">
          Relatórios
        </Link>{' '}
        &nbsp;/&nbsp; {title}
      </div>
      <div className="lineButtons row">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="contentAll">
        <Iframe url={reportEmbed} width="100%" height={`${heightIframe}px`} />
      </div>
    </>
  );
}

export default RelatorioVisualizacao;
