import { Suppliers } from '@system/enums';

export const convertStatusReservation = (status, selectedSupplier) => {
  if (selectedSupplier === Suppliers.Azul) {
    const data = {
      Padrão: {
        type: 'info',
      },
      Reservada: {
        type: 'success',
      },
      Confirmada: {
        type: 'success',
      },
      Fechada: {
        type: 'error',
      },
      Cancelada: {
        type: 'error',
      },
      Pendente: {
        type: 'error',
      },
      Arquivada: {
        type: 'warning',
      },
    };
    return data[status] ?? data['Default'];
  }

  return { type: 'info', value: status };
};
