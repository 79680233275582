import { Children, cloneElement, useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';
import { ButtonIcon } from './button-icon';
import { ButtonText } from './button-text';

const button = tv({
  base: 'm-0 w-max bg-secondary !gap-2 hover:bg-secondary-900 relative border-solid border border-transparent overflow-hidden flex flex-row items-center justify-center cursor-pointer box-border rounded-md',
  variants: {
    ghost: {
      true: 'border-solid border-secondary border bg-white hover:bg-secondary-100 text-secondary',
    },
    link: {
      true: '!border-none bg-transparent hover:bg-transparent',
    },
    size: {
      medium: '!px-4 !py-0 !min-h-9 !min-w-9',
      large: '!px-4 !py-0 !min-w-12 !min-h-12',
      xlarge: '!px-4 !py-0 !min-w-60 !min-h-12',
    },
    disabled: {
      true: ' cursor-not-allowed !bg-neutral-300 !border-neutral-300 !text-neutral-600',
    },
    loading: {
      true: 'hover:bg-none',
    },
    iconOnly: {
      true: 'rounded-full px-[10px] py-[10px]',
    },
  },
  compoundVariants: [
    {
      ghost: true,
      link: true,
      disabled: true,
      iconOnly: true,
      className: 'bg-neutral-100 border-neutral-400 hover:bg-transparent',
    },
  ],
});

const buttonCircleEffect = tv({
  base: 'absolute bg-secondary-900 !border-none rounded-r-full cursor-pointer top-0 h-full left-0 w-[0px] animate-btn-loading transition-all z-[1] ease-in-out duration-2000',
  variants: {
    ghost: {
      true: 'bg-secondary-100',
    },
  },
});

export const ButtonV2 = ({
  children,
  size = 'medium',
  loading = false,
  disabled = false,
  ghost = false,
  link = false,
  iconOnly = false,
  ...props
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => (prevProgress + 3) % 130);
      }, 50);

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const childrenWithProps = Children.map(children, (child) => {
    if (child.type === ButtonText) {
      return cloneElement(child, {
        disabled: disabled,
        ghost: ghost,
        size: size,
        link: link,
        iconOnly: iconOnly,
      });
    }
    if (child.type === ButtonIcon) {
      return cloneElement(child, {
        disabled: disabled,
        ghost: ghost,
        size: size,
        link: link,
        iconOnly: iconOnly,
      });
    }
    return child;
  });

  return (
    <button
      {...props}
      className={button({
        size: size ?? 'medium',
        disabled: disabled ?? false,
        loading: loading ?? false,
        ghost: ghost ?? false,
        link: link ?? false,
        iconOnly: iconOnly ?? false,
        class: props?.className,
      })}
    >
      {loading && (
        <div
          className={buttonCircleEffect({ ghost: ghost ?? false })}
          style={{
            width: `${progress}%`,
          }}
        />
      )}
      {childrenWithProps}
    </button>
  );
};
