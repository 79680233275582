import { useState, useEffect } from 'react';
import { BiSolidEdit } from 'react-icons/bi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import BreadCrumbs from '@components/breadcrumbs';
import api from '@services/api';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AdminCompanhias() {
  const [loading, setLoading] = useState(false);
  const [listData, seListData] = useState([]);
  const [listTotal, setListTotal] = useState([]);
  const [cod, setCod] = useState('');
  const [iata, setIata] = useState('');
  const [icao, setIcao] = useState('');
  const [nome, setNome] = useState('');
  const [tipo, setTipo] = useState('');
  const [logo, setLogo] = useState('');
  const [buscaFiltro, setBuscaFiltro] = useState('');
  const [newData, setNewData] = useState(true);
  const [masterTitle] = useState('Cias. Aéreas');
  const [nomenPC, setNomenPC] = useState('Classe 1');
  const [nomenPR, setNomenPR] = useState('Classe 2');
  const [nomenEX, setNomenEX] = useState('Classe 3');
  const [nomenEC, setNomenEC] = useState('Classe 4');
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    setLoading(true);
    let query = 'SELECT * FROM `vpcharter_companhia` ORDER BY `id`';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post('/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setListTotal(response.data.length);
        }
      })
      .catch((error) => alert(error));
    setLoading(false);
  }, []);

  function limpaForm() {
    setLoading(true);
    setNewData(true);
    setCod('');
    setIata('');
    setIcao('');
    setTipo('');
    setNome('');
    setLogo('');
    setNomenPC('Classe 1');
    setNomenPR('Classe 2');
    setNomenEX('Classe 3');
    setNomenEC('Classe 4');
    setTimeout(() => setLoading(false), 1000);
  }

  function validaForm(e) {
    setLoading(true);
    e.preventDefault();

    setTimeout(function () {
      if (!newData) {
        var query = `
          UPDATE \`vpcharter_companhia\` 
          SET 
            \`cod\` = '${cod.toUpperCase().trim()}',
            \`iata\` = '${iata.toUpperCase().trim()}',
            \`icao\` = '${icao.toUpperCase().trim()}',
            \`nome\` = '${nome.trim()}',
            \`tipo\` = '${tipo.trim()}',
            \`logo\` = '${logo}',
            \`nomen_pc\` = '${nomenPC}',
            \`nomen_pr\` = '${nomenPR}',
            \`nomen_ex\` = '${nomenEX}',
            \`nomen_ec\` = '${nomenEC}'
          WHERE \`vpcharter_companhia\`.\`id\` = ${selectedCompany.id};
        `;

        var fData = new FormData();
        fData.append('query', query);
        api
          .post('/query', fData)
          .then((response) => {
            if (response.status === 201) {
              toaster('success', 'Alterado com sucesso.');
              limpaForm();
              let query = 'SELECT * FROM `vpcharter_companhia` ORDER BY `id`';
              let fData = new FormData();
              fData.append('query', query);
              api
                .post('/query', fData)
                .then((response) => {
                  if (response.data.length > 0) {
                    limpaForm();
                    seListData(response.data);
                  }
                })
                .catch((error) => alert(error));
            }
          })
          .catch((error) => alert(error));
      } else {
        var query =
          "SELECT * FROM `vpcharter_companhia` WHERE `iata` = '" + iata + "' ";
        var fData = new FormData();
        fData.append('query', query);
        api
          .post('/query', fData)
          .then((response) => {
            if (response.data.length > 0) {
              toaster('error', 'Por favor, verifique os dados.');
            } else {
              let query =
                "INSERT INTO `vpcharter_companhia` (`cod`, `iata`, `icao`, `nome`, `tipo`, `logo`, `nomen_pc`, `nomen_pr`, `nomen_ex`, `nomen_ec`, `apelido`) VALUES ('" +
                cod +
                "', '" +
                iata +
                "', '" +
                icao +
                "', '" +
                nome +
                "', '" +
                tipo +
                "', '" +
                logo +
                "', '" +
                nomenPC +
                "', '" +
                nomenPR +
                "', '" +
                nomenEX +
                "', '" +
                nomenEC +
                "', '" +
                nome.trim().split(' ')[0] +
                "') ";
              let fData = new FormData();
              fData.append('query', query);
              api
                .post('/query', fData)
                .then((response) => {
                  if (response.status === 201) {
                    toaster('success', 'Cadastrado com sucesso.');
                    limpaForm();
                    let query =
                      'SELECT * FROM `vpcharter_companhia` ORDER BY `id` ';
                    let fData = new FormData();
                    fData.append('query', query);
                    api
                      .post('/query', fData)
                      .then((response) => {
                        if (response.data.length > 0) {
                          seListData(response.data);
                        }
                      })
                      .catch((error) => alert(error));
                  } else {
                    toaster('error', 'Erro na Gravação!.');
                  }
                })
                .catch((error) => alert(error));
            }
          })
          .catch((error) => alert(error));
      }
      setLoading(false);
    }, 1000);
  }

  function editaData(data) {
    setLoading(true);
    limpaForm();
    setNewData(false);
    setCod(data.cod);
    setIata(data.iata);
    setIcao(data.icao);
    setTipo(data.tipo);
    setNome(data.nome);
    setNomenPC(data.nomen_pc);
    setNomenPR(data.nomen_pr);
    setNomenEX(data.nomen_ex);
    setNomenEC(data.nomen_ec);
    setLogo(data.logo);
    setSelectedCompany(data);
    setTimeout(() => setLoading(false), 1000);
  }

  function listSearch() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_companhia` WHERE `iata` LIKE '%" +
      buscaFiltro +
      "%' OR `icao` LIKE '%" +
      buscaFiltro +
      "%' OR `nome` LIKE '%" +
      buscaFiltro +
      "%' OR `cidade` LIKE '%" +
      buscaFiltro +
      "%' OR `uf` LIKE '%" +
      buscaFiltro +
      "%'  ORDER BY `nome`, `uf` ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post('/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setLoading(false);
        } else {
          toaster('error', 'Dados não encontrados!');
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }
  function changeBuscaFiltro(e) {
    setBuscaFiltro(e);
    if (e.trim().length == 0) {
      setLoading(true);
      let query = 'SELECT * FROM `vpcharter_companhia`  ORDER BY `nome`, `uf` ';
      let fData = new FormData();
      fData.append('query', query);
      api
        .post('/query', fData)
        .then((response) => {
          if (response.data.length > 0) {
            seListData(response.data);
            setListTotal(response.data.length);
            setLoading(false);
          }
        })
        .catch((error) => alert(error));
    }
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      listSearch();
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="flex flex-col gap-7 pb-6">
        <BreadCrumbs
          paths={[
            { label: 'Configurações', link: PagePath.Admin },
            { label: masterTitle, link: PagePath.AdminCompanhias },
          ]}
        />
        <h1 className="m-0 text-heading-1 text-primary">{masterTitle}</h1>
      </div>

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="row">
                <div className="col">
                  <label>COD</label>
                  <input
                    type="text"
                    value={cod}
                    onChange={(e) => setCod(e.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label>IATA</label>
                  <input
                    type="text"
                    value={iata}
                    onChange={(e) => setIata(e.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label>ICAO</label>
                  <input
                    type="text"
                    value={icao}
                    onChange={(e) => setIcao(e.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label>Tipo</label>
                  <select
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    <option value="AERO">Aéreo</option>
                    <option value="RODO">Rodoviário</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label>Nome</label>
                  <input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />
                </div>
                <div className="col-6">
                  <label>URL Logo</label>
                  <input
                    type="text"
                    placeholder="http://www."
                    value={logo}
                    onChange={(e) => setLogo(e.target.value)}
                    required
                  />
                </div>

                <div className="col-12">
                  <label>
                    <b>Nomenclatura das Categorias</b>
                  </label>
                </div>

                <div className="col-3">
                  <label>Classe 1</label>
                  <input
                    type="text"
                    value={nomenPC}
                    onChange={(e) => setNomenPC(e.target.value)}
                    required
                  />
                </div>
                <div className="col-3">
                  <label>Classe 2</label>
                  <input
                    type="text"
                    value={nomenPR}
                    onChange={(e) => setNomenPR(e.target.value)}
                    required
                  />
                </div>
                <div className="col-3">
                  <label>Classe 3</label>
                  <input
                    type="text"
                    value={nomenEX}
                    onChange={(e) => setNomenEX(e.target.value)}
                    required
                  />
                </div>
                <div className="col-3">
                  <label>Classe 4</label>
                  <input
                    type="text"
                    value={nomenEC}
                    onChange={(e) => setNomenEC(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col"></div>
                <div className="col-3">
                  <button type="submit">
                    <span className={loading ? 'hide' : ''}>
                      <span className={newData ? '' : 'hide'}>
                        <PiCaretRightBold /> Salvar
                      </span>
                      <span className={!newData ? '' : 'hide'}>
                        <FiEdit /> Alterar
                      </span>
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{masterTitle}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por"
                value={buscaFiltro}
                onChange={(e) => changeBuscaFiltro(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={() => listSearch()} />
            </div>
            <div className="rightBtn">
              <div>{listData.length}</div>
              <div>de</div>
              <div className="listTotal">{listTotal}</div>
              <button
                type="button"
                className="btn btnNew"
                onClick={() => limpaForm()}
              >
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>

        <div className="row lineButtons">
          <div className="table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <th scope="col">COD</th>
                  <th scope="col">IATA</th>
                  <th scope="col">ICAO</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Nome</th>
                  <th scope="col" colSpan="4">
                    Nomenclatura das Categorias
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {listData.map((data, index) => (
                  <tr
                    key={index}
                    onClick={() => editaData(data)}
                    className={iata == data.iata ? 'trSelect' : ''}
                  >
                    <td>{data.cod}</td>
                    <td>{data.iata}</td>
                    <td>{data.icao}</td>
                    <td>{data.tipo}</td>
                    <td>{data.nome}</td>
                    <td>{data.nomen_pc}</td>
                    <td>{data.nomen_pr}</td>
                    <td>{data.nomen_ex}</td>
                    <td>{data.nomen_ec}</td>
                    <td>
                      <BiSolidEdit />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
