import { eachDayOfInterval, endOfMonth, startOfMonth } from 'date-fns';

/**
 * @param {Date} date
 */
export const getCalendarDays = (date) => {
  const startMonth = startOfMonth(date);
  const endMonth = endOfMonth(date);
  const days = eachDayOfInterval({ start: startMonth, end: endMonth });
  return days;
};
