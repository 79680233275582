import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { BiCalendar, BiUser } from 'react-icons/bi';
import { MdOutlinePlace } from 'react-icons/md';

import { ButtonV2 } from '@components/new-components/button-v2';
import { useAereoReservation } from '@hooks/useAereo';
import { Container } from '@layout';
import { formatCityAirport } from '@system/utils';

export default function SearchReservations({
  origin,
  destination,
  departureDate,
  returnDate,
  passengers,
  months,
  airports,
  nameOrigin,
  nameDestination,
}) {
  const { openForm } = useAereoReservation();

  function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ' '; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toUpperCase() : match.toLowerCase();
    });
  }

  function getAbbreviationDay(data) {
    const weekDay = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    return weekDay[data.getDay()];
  }

  function formatDate(data) {
    const weekDay = getAbbreviationDay(data);
    const formattedDate = format(data, 'dd MMM yyyy', { locale: ptBR });
    return `${weekDay}, ${formattedDate}`;
  }

  function formatMonthDate(data) {
    const formattedDate = format(data, 'MMM yyyy', { locale: ptBR });
    return `${formattedDate}`;
  }

  let date = '';
  if (departureDate && returnDate) {
    date =
      camelize(formatDate(new Date(`${departureDate} `))) +
      ' - ' +
      camelize(formatDate(new Date(`${returnDate} `)));
  }

  if (months) {
    const data_months = months.split(',');
    data_months.forEach((v) => {
      date = date + camelize(formatMonthDate(new Date(`${v}-02 `))) + ', ';
    });
    date = date.substring(0, date.length - 2);
  }

  return (
    <Container className="py-4">
      <div className="flex w-full justify-between">
        <div className="flex w-full items-center justify-start gap-5">
          <div className="flex items-center gap-2 border-x-0 border-y-0 border-r border-solid border-neutral-700 pr-3">
            <MdOutlinePlace size={15} className="text-neutral-800" />
            <span className="text-body text-neutral-800">
              {origin} (
              {nameOrigin && String(nameOrigin).toLowerCase().includes('todos')
                ? nameOrigin
                : formatCityAirport(origin, airports)}
              ) | {destination} (
              {nameDestination &&
              String(nameDestination).toLowerCase().includes('todos')
                ? nameDestination
                : formatCityAirport(destination, airports)}
              )
            </span>
          </div>
          <div className="flex items-center gap-2 border-x-0 border-y-0 border-r border-solid border-neutral-700 pr-3">
            <BiCalendar size={15} className="text-neutral-800" />
            <span className="text-body text-neutral-800">{date}</span>
          </div>
          <div className="flex items-center gap-2 pr-3">
            <BiUser size={15} className="text-neutral-800" />
            <span className="text-body text-neutral-800">
              {passengers > 1
                ? `${passengers} passageiros`
                : `${passengers} passageiro`}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <ButtonV2 ghost onClick={openForm}>
            <ButtonV2.Text>Alterar busca</ButtonV2.Text>
          </ButtonV2>
        </div>
      </div>
    </Container>
  );
}
