export function cnpjValidator(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj === '' || cnpj.length !== 14) {
    return false;
  }
  // Check if all digits are the same
  const invalidCNPJs = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];
  if (invalidCNPJs.includes(cnpj)) {
    return false;
  }
  // Validate check digits
  const length = cnpj.length - 2;
  const numbers = cnpj.substring(0, length);
  const checkDigits = cnpj.substring(length);
  const calculateCheckDigit = (numbers, position) => {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
      sum += numbers[i] * position--;
      if (position < 2) position = 9;
    }
    return sum % 11 < 2 ? 0 : 11 - (sum % 11);
  };
  const checkDigit1 = calculateCheckDigit(numbers, length - 7);
  if (checkDigit1 !== parseInt(checkDigits.charAt(0))) {
    return false;
  }
  const checkDigit2 = calculateCheckDigit(numbers + checkDigit1, length - 6);
  return checkDigit2 === parseInt(checkDigits.charAt(1));
}
