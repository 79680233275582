import { getCancelRuleText, getChangeRuleText } from '@system/utils';
import { useState } from 'react';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { useBookings } from '@stores/bookings';

const FlightRule = ({ type, rule }) => {
  let textRule = '';

  if (type == 'Cancelamento') {
    textRule = getCancelRuleText(rule);
  } else {
    textRule = getChangeRuleText(rule);
  }

  return (
    <div className="text-xs leading-5">
      <p className="m-0 font-semibold">{type}:</p>
      <p className="m-0">{textRule}</p>
    </div>
  );
};

const FlightRulesDetails = () => {
  const { flightDetail } = useBookings();

  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <div className={'flex flex-col rounded-lg bg-white p-4'}>
      {' '}
      <div
        className={
          'mb-4 flex cursor-pointer select-none flex-row items-center justify-between border-0 !border-b border-solid border-neutral-300 pb-3'
        }
        onClick={toggle}
      >
        <span className="text-heading-3 text-primary">Regras</span>
        {collapse ? (
          <PiCaretDownBold className="text-primary" size={'1.125rem'} />
        ) : (
          <PiCaretUpBold className="text-primary" size={'1.125rem'} />
        )}
      </div>
      {flightDetail?.changeRule && (
        <FlightRule type="Alteração" rule={flightDetail.changeRule} />
      )}
      {flightDetail?.cancelRule && (
        <FlightRule type="Cancelamento" rule={flightDetail.cancelRule} />
      )}
    </div>
  );
};

export default FlightRulesDetails;
