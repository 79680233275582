export function getStateNameByIata(iata, databaseAirports) {
  const airport = databaseAirports.find((airport) => airport.iata === iata);
  if (airport) {
    return getStateName(airport.uf);
  } else {
    return 'Aeroporto não encontrado';
  }
}

export const states = {
  AC: 'Acre',
  AL: 'Alagoas',
  AM: 'Amazonas',
  AP: 'Amapá',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MG: 'Minas Gerais',
  MS: 'Mato Grosso do Sul',
  MT: 'Mato Grosso',
  PA: 'Pará',
  PB: 'Paraíba',
  PE: 'Pernambuco',
  PI: 'Piauí',
  PR: 'Paraná',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RO: 'Rondônia',
  RR: 'Roraima',
  RS: 'Rio Grande do Sul',
  SC: 'Santa Catarina',
  SE: 'Sergipe',
  SP: 'São Paulo',
  TO: 'Tocantins',
};

export const statesArray = Object.entries(states).map(([value, label]) => ({
  value,
  label,
}));

export function getStateName(stateCode) {
  return `${states[stateCode]}/${stateCode}` || 'Estado não encontrado';
}
