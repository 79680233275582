/** @type {import('tailwindcss').Config} */
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors: {
        background: '#F5F5F5',
        primary: '#162260',
        'primary-900': '#000B24',
        'primary-100': '#EBEEFB',
        secondary: '#E41047',
        'secondary-900': '#B60B37',
        'secondary-100': '#F9E6E3',
        price: '#03A691',
        'neutral-800': '#343A41',
        'neutral-700': '#545A64',
        'neutral-600': '#777E88',
        'neutral-400': '#BFC4CE',
        'neutral-300': '#DFE1E7',
        'neutral-100': '#F3F4F6',
        'success-900': '#007E6C',
        'success-500': '#05A593',
        'success-300': '#8DE4C7',
        'success-100': '#E7FBF4',
        'warning-900': '#AB5F11',
        'warning-500': '#FD8B0E',
        'warning-300': '#FFBB78',
        'warning-100': '#FDF1E7',
        'error-900': '#A53421',
        'error-500': '#FF4D3E',
        'error-300': '#FB9F90',
        'error-100': '#FFEBE9',
        'info-900': '#1D62B5',
        'info-500': '#4095FD',
        'info-300': '#95C5FE',
        'info-100': '#E7F1FF',
        'transparent-white': 'rgba(255, 255, 255, 0.1)',
      },
      fontSize: {
        'heading-1': [
          '1.5rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '600',
          },
        ],
        'heading-2': [
          '1.25rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '600',
          },
        ],
        'heading-3': [
          '1rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '600',
          },
        ],
        'heading-4': [
          '1rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '400',
          },
        ],
        breadcrumbs: [
          '0.875rem',
          {
            fontFamily: 'Montserrat',
          },
        ],
        'breadcrumbs-icon': [
          '1.313rem',
          {
            fontFamily: 'Montserrat',
          },
        ],
        body: [
          '0.875rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '400',
          },
        ],
        small: [
          '0.75rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '400',
          },
        ],
        label: [
          '0.75rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '500',
          },
        ],
        link: [
          '0.875rem',
          {
            fontFamily: 'Montserrat',
            fontWeight: '600',
            lineHeight: '1.25rem',
          },
        ],
      },
      screens: {
        tablet: '640px', // Tablets
        notebook: '1024px', // Notebooks
        desktop: '1280px', // Desktops
        large: '1536px', // Large screens
      },
    },
  },
  variants: {
    extend: {
      borderColor: ['placeholder-shown', 'not-placeholder-shown'],
    },
  },
  corePlugins: {
    preflight: false,
  },
};
