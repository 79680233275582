import React, { useId } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { tv } from 'tailwind-variants';

const chipsStyles = tv({
  base: 'm-0 !h-8 flex items-center gap-2 h-max w-max border border-solid border-neutral-300 !animate-none text-body !transition-none font-semibold text-neutral-700 peer-checked:border-secondary peer-checked:text-secondary peer-checked:bg-secondary-100 hover:bg-secondary-100 hover:text-secondary hover:border-secondary-100 px-4 py-[6px] rounded-full',
});

const Chips = React.forwardRef(
  ({ label, className, onRemove, onChange }, ref) => {
    const id = useId();
    return (
      <div>
        <input
          type="checkbox"
          onChange={onChange}
          name={label}
          className="peer hidden"
          id={id}
          ref={ref}
        />
        <label htmlFor={id} className={chipsStyles({ class: className })}>
          {label}
          {onRemove && (
            <AiOutlineClose
              onClick={onRemove}
              className="z-[2] !animate-none cursor-pointer text-body !transition-none"
            />
          )}
        </label>
      </div>
    );
  },
);
Chips.displayName = 'Chips';
export { Chips };
