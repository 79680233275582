import { FlightRulesTexts } from '@system/enums/flight-rules-texts';

export function getCancelRuleText(rule) {
  let text = '';

  if (rule.cancelWithCost && rule.cancelCostPercentage) {
    text = cancellationWithCust(rule.cancelCostPercentage);
  } else if (rule.cancelWithRefund && rule.refundWithDeadline) {
    text = cancellationWithDateAndRefund(
      rule.refundPercentage,
      rule.refundPercentageDeadline,
      rule.refundDaysLimit,
    );
  } else if (rule.cancelWithRefund && rule.refundPercentage) {
    text = cancellationWithRefund(rule.refundPercentage);
  } else {
    text = cancellationWithoutCust();
  }

  return text;
}

export function getChangeRuleText(rule) {
  let text = '';

  if (!rule.changeWithCost && rule.changeLimitDays) {
    text = alterationWithDateAndCost(
      rule.changeLimitDays,
      rule.changeCostValue,
    );
  } else {
    text = alterationWithCost(rule.changeCostValue);
  }

  return text;
}

function cancellationWithCust(cust) {
  const cost = cust ? cust.replace('.', ',') : '';
  return FlightRulesTexts.CANCELLATION_WITH_CUST.replace('$cust$', cost);
}

function cancellationWithoutCust() {
  return FlightRulesTexts.CANCELLATION_WITHOUT_CUST;
}

function cancellationWithRefund(refund) {
  const cost = refund ? refund.replace('.', ',') : '';
  return FlightRulesTexts.CANCELLATION_WITH_REFUND.replace('$refund$', cost);
}

function cancellationWithDateAndRefund(refundBefore, refundAfter, daysLimit) {
  const refundBeforeValue = refundBefore ? refundBefore.replace('.', ',') : '';
  const refundAfterValue = refundAfter ? refundAfter.replace('.', ',') : '';
  return FlightRulesTexts.CANCELLATION_WITH_DATE_AND_REFUND.replace(
    '$refundBefore$',
    refundBeforeValue,
  )
    .replace('$day-01$', daysLimit)
    .replace('$day-02$', daysLimit)
    .replace('$refundAfter$', refundAfterValue);
}

function alterationWithDateAndCost(daysLimit, cust) {
  const cost = cust ? cust.replace('.', ',') : '';
  return FlightRulesTexts.ALTERATION_WITH_DATE_AND_CUST.replace(
    '$day-01$',
    daysLimit,
  )
    .replace('$day-02$', daysLimit)
    .replace('$cust$', cost);
}

function alterationWithCost(cust) {
  const cost = cust ? cust.replace('.', ',') : '';
  return FlightRulesTexts.ALTERATION_WITH_CUST.replace('$cust$', cost);
}
