import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { tv } from 'tailwind-variants';
import * as yup from 'yup';

import Globals from '@components/Globals';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import api from '@services/api';

import './style.css';

const styles = {
  button: tv({
    base: 'font-semibold',
    variants: {
      loading: {
        true: 'hidden',
      },
    },
  }),
  loader: tv({
    base: [
      'relative top-[-20px] h-4 w-4 rounded-full text-[8px] text-white',
      'before:absolute before:left-[-3.5em] before:top-0 before:h-4 before:w-4 before:rounded-full before:content-[""]',
      'after:absolute after:left-[3.5em] after:top-0 after:h-4 after:w-4 after:rounded-full after:content-[""]',
    ],
    variants: {
      loading: {
        false: 'hidden',
        true: 'vgp-spinner',
      },
    },
  }),
  email: tv({
    base: 'mt-5 text-center',
    variants: {
      send: {
        false: 'hidden',
      },
    },
  }),
  form: tv({
    base: 'mt-[60px]',
    variants: {
      hide: {
        true: 'hidden',
      },
    },
  }),
};

const schemaValidation = yup.object().shape({
  email: yup
    .string()
    .label('E-mail')
    .required('E-mail é obrigatório')
    .email('Digite um e-mail válido'),
});

export default function RecuperarSenha() {
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });

  function message(msg) {
    return (
      <div className="max-w-[600px] rounded-[5px] bg-[#ec343a] p-5 text-white">
        <h4 className="text-xl text-white">Erro ao enviar o email</h4>
        <p className="m-0 text-sm">{msg}</p>
      </div>
    );
  }

  async function onSubmit(data) {
    setLoading(true);

    try {
      await api.post(Globals.endPoint + '/authentication/recover-password', {
        email: data.email,
      });

      setIsEmailSent(true);
    } catch (err) {
      const msg =
        err.statusCode === 404
          ? 'Email não encontrado. Verifique se o email está correto.'
          : 'Houve um erro durante o envio do email. Tente novamente.';

      toast.custom(() => message(msg));
    } finally {
      setLoading(false);
    }
  }

  const email = watch('email');

  return (
    <>
      <Form
        className={styles.form({ hide: isEmailSent })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-5">
          <h2>Recuperar Senha</h2>
          <p>Por favor, digite seu e-mail de cadastro.</p>
        </div>
        <Form.Group>
          <Form.Label>E-mail</Form.Label>
          <InputV2
            type="email"
            placeholder="Digite seu e-mail"
            required
            {...register('email')}
          />
          <Form.Message error={errors?.email} />
        </Form.Group>
        <button type="submit" className="mt-[22px] h-[46px]">
          <span className={styles.button({ loading })}>Enviar</span>
          <span className={styles.loader({ loading })}></span>
        </button>
      </Form>
      <div className={styles.email({ send: isEmailSent })}>
        <h2>E-mail Enviado!</h2>
        <p>
          Em instantes você receberá o link de recuperação no e-mail{' '}
          <b>{email}</b>.
        </p>
        <p>
          Caso não encontre na caixa de entrada, por favor verifique na caixa de
          spam.
        </p>
      </div>
    </>
  );
}
