import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import { IoWarningOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import BreadCrumbs from '@components/breadcrumbs';
import { ButtonV2 } from '@components/new-components/button-v2';
import { InputCheckbox } from '@components/new-components/checkbox/input-checkbox';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '@services/api';
import { getCepData } from '@services/helpers/get-cep-data';
import { getCnpjData } from '@services/helpers/get-cnpj-data';
import {
  LINE_OF_BUSINESS_OPTIONS,
  SEGMENTS,
  SELECT_OPTIONS_CNPJ_STATUS,
  statesArray,
  PAYMENT_METHODS,
  TYPE_COMPANY,
} from '@system/utils';
import { inputFormatter } from '@system/utils/inputFormatter';
import { toaster } from '@system/utils/toaster';
import { cnpjValidator, cpfValidator } from '@system/validators';
import { PagePath } from '../pages-config';
// import { setUser } from '@sentry/react';

const schema = yup.object().shape({
  tipo_da_empresa: yup.string().required('Campo obrigatório'),
  cnpj: yup
    .string()
    .required('Campo obrigatório')
    .test(
      'cnpj-valido',
      'CNPJ não encontrado. Digite um número válido.',
      (value) => cnpjValidator(value),
    ),
  razaosocial: yup.string().required('Campo obrigatório'),
  nome_fantasia: yup.string().required('Campo obrigatório'),
  ramo_atividade: yup.string().required('Campo obrigatório'),
  segmento: yup.string().required('Campo obrigatório'),
  data_inicio_atvidades: yup
    .date()
    .typeError('Campo obrigatório')
    .required('Campo obrigatório'),
  situacao_cadastral: yup.string().required('Campo obrigatório'),
  faturamento_mensal: yup.string().required('Campo obrigatório'),
  cep: yup.string().required('Campo obrigatório'),
  endereco: yup.string().required('Campo obrigatório'),
  numero: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  bairro: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  uf: yup.string().required('Campo obrigatório'),
  responsavel: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .test('cpf-valido', 'CPF inválido', (value) => cpfValidator(value)),
  rg: yup.string().when('tipo_da_empresa', {
    is: 'Hotel',
    then: () => yup.string().required('Campo obrigatório'),
  }),
  telefone: yup.string().required('Campo obrigatório'),
  cargo: yup.string().required('Campo obrigatório'),
  comissao: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Email inválido').required('Campo obrigatório'),
  banco: yup.string().required('Campo obrigatório'),
  agencia: yup.string().required('Campo obrigatório'),
  tipo_da_conta: yup.string().required('Campo obrigatório'),
  conta: yup.string().required('Campo obrigatório'),
  allotment: yup.string(),
  vencimento: yup.string(),
  executivo_conta_id: yup.number().required('Campo obrigatório'),
  porcentagem_participacao: yup.string().when('tipo_da_empresa', {
    is: 'Agência',
    then: () => yup.string().required('Campo obrigatório'),
  }),
  metodo_de_pagamento: yup
    .array()
    .of(yup.string())
    .min(1, 'Selecione pelo menos uma forma de pagamento')
    .required('Campo obrigatório'),
  valor_do_credito: yup.string().when('metodo_de_pagamento', {
    is: (val) => {
      val ?? val.includes('faturamento');
    },
    then: yup
      .string()
      .required('Campo obrigatório quando faturamento está selecionado'),
  }),
});
export default function AdminEmpresasCadastrar() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const selectedPaymentMethods = watch('metodo_de_pagamento') || [];
  const tipoEmpresa = watch('tipo_da_empresa') || '';
  const { state } = useLocation();
  const navigate = useNavigate();
  const [usersOptions, setUsersOptions] = useState([]);
  useEffect(() => {
    const findMethods = [];
    if (state && state.id) {
      findMethods.push(getContractorInformations());
    }
    findMethods.push(getUsers());
    Promise.all(findMethods);
  }, []);
  async function getContractorInformations() {
    try {
      const { data } = await api.get(`/company/${state?.id}`);
      reset({
        data_inicio_atvidades: data.data_inicio_atividade,
        ...data,
        metodo_de_pagamento:
          data?.metodo_de_pagamento?.map((method) => method.paymentMethod) ??
          [],
        valor_do_credito:
          data?.metodo_de_pagamento?.find(
            (method) => method.paymentMethod == 'invoicing',
          )?.invoicingValue ?? 0.0,
      });
    } catch (error) {
      toaster('error', 'Erro buscando informações da empresa');
    }
  }
  async function getUsers() {
    try {
      const { data } = await api.get(`/users`);
      const usersOpts = data.map((user) => {
        return {
          value: Number(user.id),
          label: `${user.firstName} ${user.familyName}`,
        };
      });
      setUsersOptions(usersOpts);
    } catch (error) {
      toaster('error', 'Erro buscando informações de usuários');
    }
  }

  const handleCreateCompany = async (data) => {
    let newData = data;
    if (Array.isArray(newData.metodo_de_pagamento)) {
      newData.metodo_de_pagamento = newData.metodo_de_pagamento.map(
        (method) => {
          return {
            paymentMethod: method,
            active: 1,
            invoicingValue: method == 'invoicing' ? newData.valorDoCredito : 0,
          };
        },
      );
    }
    try {
      await api.post('/company', newData);
      toaster('success', 'Empresa criada com sucesso');
      navigate(PagePath.AdminEmpresas);
    } catch (error) {
      toaster('error', 'Não foi possivel criar essa empresa');
    }
  };
  const handleUpdateCompany = async (data) => {
    let newData = data;
    if (Array.isArray(newData.metodo_de_pagamento)) {
      newData.metodo_de_pagamento = newData.metodo_de_pagamento.map(
        (method) => {
          return {
            paymentMethod: method,
            active: 1,
            businessId: state?.id,
            invoicingValue:
              method == 'invoicing' ? newData.valor_do_credito : 0,
          };
        },
      );
    }
    try {
      await api.put(`/company/${state?.id}`, newData);
      toaster('success', 'Empresa atualizada com sucesso');
      navigate(PagePath.AdminEmpresas);
    } catch (error) {
      toaster('error', 'Não foi possivel atualizar essa empresa');
    }
  };
  const handleCepChange = async (event) => {
    const cep = event.target.value.replace(/\D/g, '');
    if (cep.length === 8) {
      const { complemento, bairro, localidade, uf } = await getCepData({
        cep,
      });
      setValue('bairro', bairro);
      setValue('cidade', localidade);
      setValue('uf', uf);
      setValue('complemento', complemento);
    }
  };
  const handleGetCNPJ = async (event) => {
    const cnpj = event.target.value.replace(/\D/g, '');
    if (cnpj.length === 14) {
      try {
        const {
          razao_social,
          estabelecimento: {
            nome_fantasia,
            situacao_cadastral,
            data_inicio_atividade,
          },
        } = await getCnpjData({ cnpj });
        setValue('nome_fantasia', nome_fantasia);
        setValue('razaosocial', razao_social);
        setValue('situacao_cadastral', situacao_cadastral);
        setValue('data_inicio_atividades', data_inicio_atividade);
      } catch (error) {
        toaster('error', 'Não foi possivel encontrar esse CNPJ');
      }
    }
  };
  return (
    <Form
      onSubmit={handleSubmit(
        state && state.id ? handleUpdateCompany : handleCreateCompany,
      )}
    >
      <div className="flex flex-col pb-3">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Configurações', link: PagePath.Admin },
            { label: 'Empresas', link: PagePath.AdminEmpresas },
            {
              label: state && state.id ? 'Editar' : 'Cadastrar',
              link: PagePath.AdminEmpresasCadastrar,
            },
          ]}
        />
        <div className="mb-5 mt-8 flex w-full items-center justify-between">
          <h1 className="m-0 text-heading-1 text-primary">
            {state && state.id
              ? 'Editar cadastro de nova empresa'
              : 'Cadastro nova empresa'}
          </h1>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-col gap-4 rounded-md bg-white p-4">
            <h4 className="heading-2 m-0 text-primary">Dados cadastrais</h4>
            <div className="flex w-full gap-[10px]">
              <Form.Group className="w-full">
                <Form.Label>Tipo</Form.Label>
                <SelectV2
                  options={TYPE_COMPANY}
                  {...register('tipo_da_empresa')}
                  value={getValues('tipo_da_empresa')}
                  placeholder="Selecione"
                />
                <Form.Message error={errors.tipo_da_empresa} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>CNPJ</Form.Label>
                <InputV2
                  placeholder="Digite o CNPJ da empresa"
                  {...register('cnpj')}
                  onChange={(event) => {
                    inputFormatter({ event, maskString: '99.999.999/9999-99' });
                    handleGetCNPJ(event);
                  }}
                />
                <Form.Message error={errors.cnpj} />
              </Form.Group>
            </div>
            <div className="flex w-full gap-[10px]">
              <Form.Group className="w-full">
                <Form.Label>Razão social</Form.Label>
                <InputV2
                  placeholder="Digite a razão social"
                  {...register('razaosocial')}
                />
                <Form.Message error={errors.razaosocial} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Nome fantasia</Form.Label>
                <InputV2
                  placeholder="Digite o nome fantasia"
                  {...register('nome_fantasia')}
                />
                <Form.Message error={errors.nome_fantasia} />
              </Form.Group>
            </div>
            <div className="grid grid-cols-3 grid-rows-2 gap-[10px]">
              <Form.Group className="w-full">
                <Form.Label>Ramo de atividade</Form.Label>
                <SelectV2
                  options={LINE_OF_BUSINESS_OPTIONS}
                  placeholder="Selecione"
                  value={getValues('ramo_atividade')}
                  {...register('ramo_atividade')}
                />
                <Form.Message error={errors.ramo_atividade} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Segmento</Form.Label>
                <SelectV2
                  options={SEGMENTS}
                  placeholder="Selecione"
                  value={getValues('segmento')}
                  {...register('segmento')}
                />
                <Form.Message error={errors.segmento} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Data de abertura</Form.Label>
                <InputV2.Icon icon={<BiCalendar className="m-0 p-0" />} />
                <InputV2
                  placeholder="Insira a data da abertura"
                  {...register('data_inicio_atvidades')}
                  type="date"
                />
                <Form.Message error={errors.data_inicio_atvidades} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Situação cadastral</Form.Label>
                <SelectV2
                  options={SELECT_OPTIONS_CNPJ_STATUS}
                  placeholder="Insira a situação cadastral"
                  value={getValues('situacao_cadastral')}
                  {...register('situacao_cadastral')}
                />
                <Form.Message error={errors.situacao_cadastral} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Faturamento mensal</Form.Label>
                <InputV2
                  placeholder="Insira o faturamento mensal"
                  {...register('faturamento_mensal')}
                />
                <Form.Message error={errors.faturamento_mensal} />
              </Form.Group>
              <Controller
                name="cep"
                control={control}
                render={({ field }) => (
                  <Form.Group className="w-full">
                    <Form.Label>CEP</Form.Label>
                    <InputV2
                      {...field}
                      placeholder="Digite o CEP"
                      {...register('cep')}
                      onChange={(event) => {
                        inputFormatter({ event, maskString: '99999-999' });
                        field.onChange(event);
                        handleCepChange(event);
                      }}
                    />
                    <Form.Message errors={errors.cep} />
                  </Form.Group>
                )}
              />
            </div>
            <div className="grid grid-cols-3 grid-rows-1 gap-[10px]">
              <Form.Group className="col-span-2 w-full">
                <Form.Label>Endereço</Form.Label>
                <InputV2
                  placeholder="Digite o endereço"
                  {...register('endereco')}
                />
                <Form.Message error={errors.endereco} />
              </Form.Group>
              <div className="col-start-3 flex gap-[10px]">
                <Form.Group className="w-full">
                  <Form.Label>Número</Form.Label>
                  <InputV2
                    placeholder="Digite o número"
                    {...register('numero')}
                    type="number"
                  />
                  <Form.Message error={errors.numero} />
                </Form.Group>
                <Form.Group className="w-full">
                  <Form.Label>Complemento</Form.Label>
                  <InputV2
                    placeholder="Digite o complemento"
                    {...register('complemento')}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="grid grid-cols-5 grid-rows-1 gap-[10px]">
              <Form.Group className="col-span-2 w-full">
                <Form.Label>Bairro</Form.Label>
                <InputV2
                  placeholder="Digite o bairro"
                  {...register('bairro')}
                />
                <Form.Message error={errors.bairro} />
              </Form.Group>
              <Form.Group className="col-span-2 w-full">
                <Form.Label>Cidade</Form.Label>
                <InputV2
                  placeholder="Digite a cidade da empresa"
                  {...register('cidade')}
                />
                <Form.Message error={errors.cidade} />
              </Form.Group>
              <Form.Group className="col-start-5">
                <Form.Label>Estado</Form.Label>
                <SelectV2
                  options={statesArray}
                  placeholder="Selecione"
                  value={getValues('uf')}
                  {...register('uf')}
                />
                <Form.Message error={errors.uf} />
              </Form.Group>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4 rounded-md bg-white p-4">
            <h4 className="heading-2 m-0 text-primary">
              Dados do proprietário ou representante legal
            </h4>
            <div className="grid grid-cols-3 grid-rows-1 gap-[10px]">
              <Form.Group className="w-full">
                <Form.Label>Nome completo</Form.Label>
                <InputV2
                  placeholder="Digite o nome completo"
                  {...register('responsavel')}
                />
                <Form.Message error={errors.responsavel} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>CPF</Form.Label>
                <InputV2
                  placeholder="Digite o CPF"
                  {...register('cpf')}
                  onChange={(event) =>
                    inputFormatter({ event, maskString: '999.999.999-99' })
                  }
                />
                <Form.Message error={errors.cpf} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Número do celular</Form.Label>
                <InputV2
                  placeholder="Digite o número do celular"
                  {...register('telefone')}
                  onChange={(event) =>
                    inputFormatter({ event, maskString: '(99) 99999-9999' })
                  }
                />
                <Form.Message error={errors.telefone} />
              </Form.Group>
            </div>
            <div className={`grid grid-cols-4 grid-rows-1 gap-[10px]`}>
              <Form.Group className="w-full">
                <Form.Label>Cargo</Form.Label>
                <InputV2 placeholder="Digite o cargo" {...register('cargo')} />
                <Form.Message error={errors.cargo} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Participação (%)</Form.Label>
                <InputV2
                  placeholder="Digite a participação em %"
                  {...register('comissao')}
                  type="number"
                />
                <Form.Message error={errors.comissao} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>E-mail</Form.Label>
                <InputV2 placeholder="Digite o e-mail" {...register('email')} />
                <Form.Message error={errors.email} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>RG</Form.Label>
                <InputV2 placeholder="Digite o RG" {...register('rg')} />
                <Form.Message error={errors.rg} />
              </Form.Group>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4 rounded-md bg-white p-4">
            <h4 className="heading-2 m-0 text-primary">Dados bancários</h4>
            <div className="grid grid-cols-4 grid-rows-1 gap-[10px]">
              <Form.Group className="w-full">
                <Form.Label>Banco</Form.Label>
                <InputV2
                  placeholder="Digite o nome do banco"
                  {...register('banco')}
                />
                <Form.Message error={errors.banco} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Agência</Form.Label>
                <InputV2
                  placeholder="Digite a agência"
                  {...register('agencia')}
                />
                <Form.Message error={errors.agencia} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Tipo de conta</Form.Label>
                <SelectV2
                  placeholder="Selecione"
                  options={[
                    { value: 'conta corrente', label: 'Conta Corrente' },
                  ]}
                  value={getValues('tipo_da_conta')}
                  {...register('tipo_da_conta')}
                />
                <Form.Message error={errors.tipo_da_conta} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Conta</Form.Label>
                <InputV2 placeholder="Digite a conta" {...register('conta')} />
                <Form.Message error={errors.conta} />
              </Form.Group>
            </div>
          </div>
          <div className="flex w-full flex-col gap-x-4 gap-y-3 rounded-md bg-white p-4">
            <div className="flex flex-col gap-1.5">
              <h4 className="heading-2 m-0 text-primary">
                Formas de pagamento
              </h4>
              <span className="m-0 text-body text-neutral-700">
                Escolha pelo menos uma forma de pagamento.
              </span>
            </div>
            <div className="flex flex-row gap-x-9">
              {PAYMENT_METHODS.map((method) => (
                <InputCheckbox
                  key={method.label}
                  {...register('metodo_de_pagamento')}
                  value={method.value}
                  checked={selectedPaymentMethods?.includes(method.value)}
                  label={method.label}
                  disabled={!method.active}
                />
              ))}
            </div>
            <span
              className={
                errors.metodo_de_pagamento
                  ? 'visible flex flex-row items-center gap-1 text-xs font-normal text-error-900'
                  : 'hidden'
              }
            >
              <IoWarningOutline size={'0.75rem'} />
              Campo obrigatório
            </span>
            <div
              className={
                selectedPaymentMethods?.includes('invoicing')
                  ? 'visible max-w-72'
                  : 'hidden'
              }
            >
              <Form.Group>
                <Form.Label>Valor do crédito</Form.Label>
                <InputV2
                  {...register('valor_do_credito')}
                  placeholder="Digite o valor do crédito em R$"
                  type="number"
                />
              </Form.Group>
            </div>
          </div>
          <div className="flex w-full flex-col gap-4 rounded-md bg-white p-4">
            <h4 className="heading-2 m-0 text-primary">Dados comerciais</h4>
            <div className="flex gap-[10px]">
              <Form.Group className="w-full">
                <Form.Label>Allotment</Form.Label>
                <InputV2
                  placeholder="Digite a quantidade de assentos negociados"
                  {...register('allotment')}
                  type="number"
                />
                <Form.Message error={errors.allotment} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Dia do vencimento</Form.Label>
                <InputV2
                  placeholder="Digite o dia do vencimento"
                  {...register('vencimento')}
                  type="number"
                />
                <Form.Message error={errors.vencimento} />
              </Form.Group>
            </div>
            <div
              className={`grid  gap-4 ${tipoEmpresa == 'Hotel' ? 'grid-cols-1' : 'grid-cols-2'}`}
            >
              <Form.Group
                className={tipoEmpresa == 'Hotel' ? 'hidden' : 'visible w-full'}
              >
                <Form.Label>% de comissão</Form.Label>
                <InputV2
                  placeholder="Digite o % de comissão"
                  {...register('porcentagem_participacao')}
                  type="number"
                />
                <Form.Message error={errors.porcentagem_participacao} />
              </Form.Group>
              <Form.Group className="w-full">
                <Form.Label>Nome do executivo de contas</Form.Label>
                <SelectV2
                  options={usersOptions}
                  placeholder="Selecione"
                  value={getValues('executivo_conta_id')}
                  {...register('executivo_conta_id')}
                />
                <Form.Message error={errors.executivo_conta_id} />
              </Form.Group>
            </div>
          </div>
          <div className="flex justify-end">
            <ButtonV2 size="large" className="w-[200px]" type="submit">
              <ButtonV2.Text>Salvar</ButtonV2.Text>
            </ButtonV2>
          </div>
        </div>
      </div>
    </Form>
  );
}
