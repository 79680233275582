import { create } from 'zustand';
import api from '@services/api';

export const useAirports = create((set) => ({
  //States
  airports: [],
  methods: {
    getAirports: () => {
      const queryGetAirports =
        'SELECT * FROM `vpcharter_aeroportos` order by `uf` ';
      const formDataGetAirports = new FormData();
      formDataGetAirports.append('query', queryGetAirports);
      api
        .post('/query', formDataGetAirports)
        .then((response) => {
          if (response.data.length > 0) {
            set({ airports: response.data });
          }
        })
        .catch((error) => console.error(error));
    },
  },
}));
