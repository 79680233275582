import { CancellationRequestStatus } from '@system/enums';
export const convertCancellationRequestStatus = (key) => {
  const converter = {
    [CancellationRequestStatus.canceled]: 'Cancelado',
    [CancellationRequestStatus.confirmed]: 'Confirmado',
    [CancellationRequestStatus.pending]: 'Pendente',
    [CancellationRequestStatus.review]: 'Em Análise',
    [CancellationRequestStatus.awaiting]: 'Aguardando pagamento',
  };
  return converter[key] ?? '';
};
