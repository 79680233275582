import { cloneElement, isValidElement } from 'react';
import { MenuItem } from './menu-item.jsx';

const Menu = ({ children, className, submenu = false, ...props }) => {
  return (
    <div className={['home-menu-content', className ?? ''].join(' ')}>
      {(children && !Array.isArray(children) ? [children] : children ?? [])
        .filter((c) => isValidElement(c))
        .map((c, idx) => cloneElement(c, { submenu, key: `submenu-${idx}` }))}
    </div>
  );
};

Menu.Item = MenuItem;
export { Menu };
