import { BiSolidChevronRight } from 'react-icons/bi';
import { MdOutlineHome } from 'react-icons/md';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { PagePath } from '../pages-config';

export default function Hotel() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal unavailableModal">
          <AiOutlineClose
            className="closeModalTrash"
            onClick={() => setOpenModal(false)}
          />
          <h2>Obrigado!</h2>
          <span>Você será avisado quando estiver disponível.</span>
        </div>
      </div>

      <div className="breadCrumb mx-auto my-0">
        <MdOutlineHome className="icoBread" />
        <BiSolidChevronRight className="caretRight" />{' '}
        <Link to={PagePath.Home} relative="path">
          Home
        </Link>
        &nbsp; / Hotel
      </div>
      <div className="contentAll msnAguarde">
        <span>
          Este produto ainda não está disponível. Aguarde, em breve teremos
          novidades!
        </span>
        <button onClick={() => setOpenModal(true)}>
          Avisar quando tiver disponível
        </button>
      </div>
    </>
  );
}
