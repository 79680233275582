import { forwardRef, useId } from 'react';
import { BiSearch } from 'react-icons/bi';

// eslint-disable-next-line react/display-name
export const SearchInput = forwardRef(
  ({ className, handleSearch, ...props }, ref) => {
    const id = useId();
    const hasLabelData = !props.label ? 'hidden' : '';

    return (
      <div className="flex w-full flex-col items-start justify-between gap-2">
        <label
          htmlFor={id}
          className={`m-0 h-5 text-sm font-medium text-neutral-800 ${hasLabelData}`}
        >
          {props.label}
        </label>
        <div className="flex h-12 w-full items-center justify-between gap-2 rounded-md !border !border-solid px-1">
          <input
            id={id}
            ref={ref}
            className={`h-full w-full border-none bg-transparent px-4 py-[14px] text-sm font-normal outline-none placeholder:italic ${className}`}
            {...props}
          />
          <BiSearch
            size={20}
            className="m-0 h-max w-max cursor-pointer rounded-md p-2 text-neutral-800 hover:bg-neutral-100"
            onClick={handleSearch}
          />
        </div>
      </div>
    );
  },
);
