import { forwardRef, useId } from 'react';
import { GoAlert } from 'react-icons/go';

export const InputText = forwardRef(function Input(
  {
    className,
    type = 'text',
    label,
    icon: Icon,
    iconPosition = 'left',
    error = null,
    isErrorMessage = true,
    ...props
  },
  ref,
) {
  const id = useId();
  const iconPositionClass =
    iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse';

  return (
    <div className="flex w-full flex-col items-start gap-2">
      {label && (
        <label
          htmlFor={id}
          className={`m-0 h-5 text-sm font-medium text-neutral-800`}
        >
          {label}
        </label>
      )}
      <div
        aria-required={!!error}
        className={`
        flex h-12 w-full items-center
        justify-between rounded-md border
        border-solid border-neutral-600 bg-transparent
        px-4 py-3.5 duration-0 has-[:focus]:border-2
        has-[:disabled]:border-transparent
        has-[:focus]:border-black has-[:disabled]:bg-neutral-300 aria-required:border-error-900
        ${iconPositionClass}
        ${className}
        `}
      >
        {Icon && <Icon className="mr-2.5" size="20" />}
        <input
          {...props}
          id={id}
          ref={ref}
          type={type}
          className="!border-0 !p-0 text-sm font-normal outline-none placeholder:italic disabled:bg-neutral-300"
        />
      </div>
      {error && isErrorMessage && (
        <div className="flex">
          <GoAlert className="mr-[5px] text-error-900" />
          <span className="text-xs font-normal text-error-900">{error}</span>
        </div>
      )}
    </div>
  );
});
