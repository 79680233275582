import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { tv } from 'tailwind-variants';
import * as yup from 'yup';

import { PagePath } from '@/pages-config';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import api from '@services/api';

import './style.css';

const styles = {
  button: tv({
    base: 'font-semibold',
    variants: {
      loading: {
        true: 'hidden',
      },
    },
  }),
  loader: tv({
    base: [
      'relative top-[-20px] h-4 w-4 rounded-full text-[8px] text-white',
      'before:absolute before:left-[-3.5em] before:top-0 before:h-4 before:w-4 before:rounded-full before:content-[""]',
      'after:absolute after:left-[3.5em] after:top-0 after:h-4 after:w-4 after:rounded-full after:content-[""]',
    ],
    variants: {
      loading: {
        false: 'hidden',
        true: 'vgp-spinner',
      },
    },
  }),
  form: tv({
    variants: {
      hide: {
        true: 'hidden',
      },
    },
  }),
  message: tv({
    base: 'mt-5 text-center',
    variants: {
      hide: {
        false: 'hidden',
      },
    },
  }),
};

const schemaValidation = yup.object().shape({
  name: yup.string().required('Nome completo é obrigatório'),
  email: yup
    .string()
    .required('E-mail é obrigatório')
    .email('Digite um e-mail válido'),
  agency: yup.string().required('Nome da Agência de Turismo é obrigatório'),
});

export default function NovoUsuario() {
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    mode: 'all',
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await api.post('/leads', data);
      if (response.status === 201) setIsEmailSent(true);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        className={styles.form({ hide: isEmailSent })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-5 mt-5">
          <h2>Saiba mais sobre a plataforma.</h2>
          <p>Por favor, digite seus dados.</p>
        </div>
        <Form.Group>
          <Form.Label htmlFor="name">Nome Completo</Form.Label>
          <InputV2
            type="text"
            name="name"
            placeholder="Digite seu nome completo"
            {...register('name')}
          />
          <Form.Message error={errors?.name} />
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Label>E-mail</Form.Label>
          <InputV2
            type="email"
            name="email"
            placeholder="Digite seu e-mail"
            {...register('email')}
          />
          <Form.Message error={errors?.email} />
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Label>Agência de Turismo</Form.Label>
          <InputV2
            type="text"
            name="agency"
            placeholder="Digite o nome da Agência de Turismo"
            {...register('agency')}
          />
          <Form.Message error={errors?.agency} />
        </Form.Group>
        <button type="submit" className="mt-[22px] h-[46px]">
          <span className={styles.button({ loading })}>Enviar</span>
          <span className={styles.loader({ loading })}></span>
        </button>
      </Form>
      <div className={styles.message({ hide: isEmailSent })}>
        <h2>Obrigado!</h2>
        <p>Entraremos em contato em breve.</p>
        <Link to={PagePath.Home} relative="path" className="inline-block">
          <ButtonV2 ghost>
            <ButtonV2.Text>Voltar</ButtonV2.Text>
          </ButtonV2>
        </Link>
      </div>
    </>
  );
}
