export const PaymentStatus = {
  Pendente: 9,
  Pago: 12,
};

export const PaymentMethod = {
  Faturamento: 'billing',
  'Cartão de Crédito': 'credit-card',
  Pix: 'pix',
  VPCard: 'vp-card',
  'Pix Agendado': 'pix-scheduled',
};

export const PaymentCategory = {
  Reserva: 'booking',
  Alteração: 'alteration',
  Cancelamento: 'cancellation',
};
