import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import CreditAllocationInfo from '@components/credit-allocation-info';
import FlightDetails from '@components/flight-details';
import FlightRulesDetails from '@components/flight-rules-details';
import LoadingAnimation from '@components/LoadingAnimation';
import { useAuthentication } from '@stores/authentication';
import { useBookings } from '@stores/bookings';
import { PaymentMethod } from '@system/enums';
import { PagePath } from '/src/pages-config';

export default function CheckoutSteps() {
  const navigate = useNavigate();
  const { state: params, pathname } = useLocation();
  const { loading, methods, booking, flightDetail } = useBookings();
  const [hasAnyRules, setHasAnyRules] = useState(false);

  const { session } = useAuthentication();

  const styles = {
    container: tv({
      base: 'mx-auto  bg-white p-5 items-center rounded-md relative   w-full',
      variants: {
        sticky: {
          true: '',
        },
      },
    }),
  };
  const steps = {
    [PagePath.CheckoutV2]: 'payment',
    [PagePath.CheckoutV2Confirmacao]: 'confirmation',
  };

  const currentStep = steps[pathname] ?? 'payment';

  const handleGoBack = () => {
    methods.reset();
    navigate(PagePath.AereoReservas, { replace: true });
  };

  const useAllotments = true;
  const useCredits = booking?.paymentMethod === PaymentMethod.Faturamento;

  useEffect(() => {
    methods.getFlightDetails(params.charterId);
  }, []);

  useEffect(() => {
    if (flightDetail)
      setHasAnyRules(flightDetail.cancelRule || flightDetail.changeRule);
  }, [flightDetail]);

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="box-border flex w-full flex-row items-center justify-between bg-white p-4">
        <a
          href="#"
          onClick={handleGoBack}
          className="box-border w-auto whitespace-nowrap text-left"
        >
          Voltar
        </a>
        <div className="flex w-full items-center justify-center">
          {/* Stepper */}
          <ul className="flex flex-row items-center justify-center gap-x-16">
            <li className="!mb-2 inline-flex flex-col items-center gap-y-2">
              <div
                className={`flex size-[1.875rem] shrink-0 flex-col content-center items-center justify-center rounded-full text-center text-heading-3 text-white ${
                  currentStep === 'payment' ? 'bg-secondary' : 'bg-neutral-400'
                }`}
              >
                1
              </div>
              <div
                className={`whitespace-nowrap text-center text-body font-normal ${
                  currentStep === 'payment'
                    ? 'text-secondary'
                    : 'text-neutral-400'
                }`}
              >
                Pagamento
              </div>
            </li>
            <li className="!mb-2 inline-flex flex-col items-center gap-y-2">
              <div
                className={`flex size-[1.875rem] shrink-0 flex-col content-center items-center justify-center rounded-full bg-neutral-400 text-center text-heading-3 text-white ${
                  currentStep === 'confirmation'
                    ? 'bg-secondary'
                    : 'bg-neutral-400'
                }`}
              >
                2
              </div>
              <div
                className={`whitespace-nowrap text-center text-body font-normal ${
                  currentStep === 'confirmation'
                    ? 'text-secondary'
                    : 'text-neutral-400'
                }`}
              >
                Confirmação
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex w-full flex-row gap-4 p-4 sm:flex-nowrap">
        <Outlet />
        <div className="flex h-auto w-full max-w-[361px] shrink-0 grow-0 flex-col gap-y-3 self-start sm:inline-flex sm:w-4/12">
          {/* <CreditAllocationInfo
            vertical
            showAllotments={useAllotments}
            showCredit={useCredits}
          /> */}
          <div className={styles.container({ sticky: true })}>
            <div>
              <span className="text-heading-3 text-primary">
                {session?.agency?.nomeFantasia ?? ''}
              </span>
            </div>
          </div>
          <FlightDetails />
          {/* {hasAnyRules && <FlightRulesDetails />} */}
        </div>
      </div>
    </>
  );
}
