// SELECT-INPUTS

import { Role } from '@system/acl';

export const SELECT_OPTIONS_ADMIN = [
  { value: Role.Operacional, label: 'Operacional' },
  { value: Role.Financeiro, label: 'Financeiro' },
  { value: Role.ProdutosInternos, label: 'Produtos' },
  { value: Role.Comprador, label: 'Comprador' },
  { value: Role.Gestao, label: 'Gestão' },
  { value: Role.Administrador, label: 'Administrador' },
  { value: Role.CompradorAdministrador, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_BUYER_ADMIN = [
  { value: Role.Comprador, label: 'Comprador' },
  { value: Role.CompradorAdministrador, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_STATUS = [
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' },
];

export const SELECT_OPTIONS_CNPJ_STATUS = [
  { value: 'Ativo', label: 'Ativo' },
  { value: 'Baixada', label: 'Baixada' },
];

export const TYPE_COMPANY = [
  { label: 'Hotel', value: 'Hotel' },
  { label: 'Agência', value: 'Agência' },
];

export const PAYMENT_METHODS = [
  { label: 'Faturamento', value: 'invoicing', active: true },
  {
    label: 'Cartão de crédito cliente',
    value: 'client_credit_card',
    active: true,
  },
  { label: 'VPCard', value: 'vpcard', active: false },
  { label: 'Pix', value: 'pix', active: false },
  { label: 'Pix agendado', value: 'scheduled_pix', active: false },
];

export const SEGMENTS = [
  { value: '98', label: '0098 - Acessorios Feminino e Lingeries' },
  { value: '185', label: '0185 - Acompanhante' },
  { value: '78', label: '0078 - Açougueiros/Comidas Congeladas' },
  { value: '29', label: '0029 - Aeroportos' },
  { value: '200', label: '0200 - Agência de Empregos e Serviços Temporários' },
  {
    value: '194',
    label: '0194 - Agências de Análise de Crédito de Consumidores',
  },
  {
    value: '220',
    label: '0220 - Air Conditioning and Refrigeration Repair Shops',
  },
  { value: '105', label: '0105 - Alfaiates / Costureiras (os)' },
  { value: '206', label: '0206 - Aluguel de Maquinas e Equipamentos' },
  { value: '189', label: '0189 - Aluguel de Roupas' },
  { value: '19', label: '0019 - Ambulance Services' },
  { value: '161', label: '0161 - Animais / Comidas e Suprimentos' },
  { value: '125', label: '0125 - Antiguidades / Venda / Conserto' },
  { value: '128', label: '0128 - Antique Reproduction Store' },
  { value: '193', label: '0193 - Anúncios / Classificados / Propaganda' },
  {
    value: '114',
    label: '0114 - Aparelhos de Som / Televisores / Eletrodomésticos',
  },
  { value: '242', label: '0242 - Aquários e Museus Aquáticos' },
  { value: '138', label: '0138 - Armarinhos / Tecidos' },
  {
    value: '24',
    label:
      '0024 - Armazenamento - Produtos Agrícolas, Mercadorias Refrigeradas, Bens',
  },
  { value: '269', label: '0269 - Arquitetura e Engenharia' },
  { value: '124', label: '0124 - Artigos de Segunda Mão / Usados' },
  { value: '130', label: '0130 - Artigos para Caça, Pesca e Camping' },
  { value: '107', label: '0107 - Artigos Unissex' },
  { value: '149', label: '0149 - Artist Supply Stores' },
  { value: '264', label: '0264 - Associação Cívica e Social' },
  { value: '71', label: '0071 - Atacadista' },
  {
    value: '56',
    label:
      '0056 - Atacadistas e Distribuidores de Tecidos e Produtos de Armarinho',
  },
  { value: '85', label: '0085 - Auto Store' },
  { value: '167', label: '0167 - Automated Cash Disbursements' },
  { value: '215', label: '0215 - Automotive Paint Shops' },
  { value: '95', label: '0095 - Automóveis' },
  { value: '84', label: '0084 - Automóveis Usados' },
  { value: '274', label: '0274 - Bail and Bond Payments' },
  { value: '160', label: '0160 - Banca de Jornal' },
  {
    value: '168',
    label: '0168 - Bancos / Lojas de Poupança e Instituições Financeiras',
  },
  {
    value: '231',
    label: '0231 - Bandas, Artistas, Orquestras e Casas de Shows',
  },
  { value: '90', label: '0090 - Barcos' },
  { value: '120', label: '0120 - Bares' },
  { value: '123', label: '0123 - Bebidas' },
  { value: '129', label: '0129 - Bicicletas / Venda e Serviço' },
  { value: '233', label: '0233 - Boliche' },
  { value: '214', label: '0214 - Borracheiro' },
  { value: '31', label: '0031 - Bridge and Road Fees' },
  { value: '134', label: '0134 - Brinquedos e Jogos' },
  { value: '188', label: '0188 - Buying/Shopping Clubs' },
  { value: '135', label: '0135 - Câmeras Fotográficas, Acessórios e Som' },
  { value: '91', label: '0091 - Camper Dealers' },
  { value: '176', label: '0176 - Camping e Estacionamento de Trailers' },
  { value: '234', label: '0234 - Campo de Esporte / Propaganda Esportiva' },
  { value: '180', label: '0180 - Carpet and Upholstery Cleaning' },
  { value: '8', label: '0008 - Carpintaria - Serviços' },
  { value: '205', label: '0205 - Carros Blindados / Detetives / Proteção' },
  { value: '83', label: '0083 - Carros e Caminhões (Novos e Usados)' },
  { value: '239', label: '0239 - Casa Lotéricas, Bingos e Cassinos' },
  { value: '169', label: '0169 - Casas de Câmbio' },
  { value: '82', label: '0082 - Casas de Carnes/Hortfutigrangeiros' },
  { value: '190', label: '0190 - Casas de Massagem e Saunas' },
  { value: '251', label: '0251 - Casas de Repouso / Serviço de Enfermagem' },
  { value: '262', label: '0262 - Child Care Services' },
  { value: '99', label: '0099 - Children’s and Infants’ Wear Stores' },
  { value: '247', label: '0247 - Chiropractors' },
  { value: '227', label: '0227 - Cinema' },
  { value: '191', label: '0191 - Clínicas de Beleza - SPA' },
  { value: '241', label: '0241 - Clubes / Atletismo - Sócios' },
  {
    value: '208',
    label: '0208 - Comerciais - Não Classificados Anteriormente',
  },
  {
    value: '157',
    label: '0157 - Comércio de Combustíveis / Carvão e Lenha/Gás',
  },
  { value: '45', label: '0045 - Comércio de Equipamentos não Classificados' },
  {
    value: '57',
    label: '0057 - Comércio de Roupas e Uniformes Adulto e Infantil',
  },
  { value: '76', label: '0076 - Comércio em Geral' },
  { value: '28', label: '0028 - Companhias Aéreas e Taxi Aereo' },
  { value: '10', label: '0010 - Concreto - Serviços' },
  { value: '183', label: '0183 - Conserto de Chapéus / Sapatos' },
  { value: '203', label: '0203 - Conserto e Manutenção de Computadores' },
  { value: '221', label: '0221 - Consertos Elétricos' },
  { value: '270', label: '0270 - Contadores' },
  { value: '2', label: '0002 - Cooperativas Agrícolas' },
  {
    value: '32',
    label:
      '0032 - Cooperativas de Taxi e Serviços de Transporte não Classificados',
  },
  { value: '170', label: '0170 - Corretor de Seguros' },
  { value: '23', label: '0023 - Courier' },
  { value: '272', label: '0272 - Court Costs including Alim' },
  { value: '184', label: '0184 - Crematórios / Funerárias' },
  { value: '25', label: '0025 - Cruzeiros e Cia Marítima' },
  { value: '139', label: '0139 - Crystal and Glassware Stores' },
  { value: '229', label: '0229 - Dança (Academias)' },
  {
    value: '13',
    label: '0013 - Datilografia, Digitação e Serviços Relacionados',
  },
  { value: '187', label: '0187 - Debt' },
  { value: '198', label: '0198 - Dedetização' },
  { value: '245', label: '0245 - Dentistas / Ortodontistas - Clínicas' },
  { value: '195', label: '0195 - Design' },
  { value: '146', label: '0146 - Direct Marketing' },
  { value: '145', label: '0145 - Direct Marketing' },
  { value: '144', label: '0144 - Direct Marketing' },
  { value: '143', label: '0143 - Direct Marketing' },
  { value: '147', label: '0147 - Direct Marketing' },
  { value: '141', label: '0141 - Direct Marketing' },
  { value: '148', label: '0148 - Direct Marketing' },
  { value: '142', label: '0142 - Direct Marketing' },
  {
    value: '31',
    label: '0031 - Distribuição / Energia / Gás e Outras Utilidades',
  },
  { value: '111', label: '0111 - Drogarias / Perfumes / Cosméticos' },
  { value: '66', label: '0066 - Educação' },
  { value: '30', label: '0030 - Editores / Livros e Revistas' },
  {
    value: '238',
    label: '0238 - Entretenimento (Teatro e Cinema) / Locadoras',
  },
  { value: '216', label: '0216 - Estacionamento' },
  { value: '165', label: '0165 - Estética e Cosméticos' },
  { value: '237', label: '0237 - Estrada de Ferro / Estações e Terminais' },
  { value: '64', label: '0064 - Estúdios Fotográficos' },
  { value: '48', label: '0048 - Fazendas e Horticulturas' },
  {
    value: '164',
    label: '0164 - Festas e Eventos / Aluguel de Equipamentos e Organização',
  },
  { value: '88', label: '0088 - Financeiras' },
  { value: '17', label: '0017 - Finishing Contracting' },
  { value: '39', label: '0039 - Floras e Paisagismo' },
  {
    value: '202',
    label: '0202 - Florestal / Produtos / Equipamentos e Suprimentos',
  },
  { value: '156', label: '0156 - Flutuantes e Barcos Residência' },
  { value: '11', label: '0011 - Fundações e Brocas' },
  { value: '54', label: '0054 - Fundição' },
  { value: '166', label: '0166 - Gambling Transactions' },
  { value: '172', label: '0172 - Game Systems and Software' },
  { value: '233', label: '0233 - Golf' },
  { value: '37', label: '0037 - Graxas e Lubrificantes' },
  { value: '128', label: '0128 - Guest House' },
  { value: '186', label: '0186 - Hospitalização, Planos' },
  { value: '49', label: '0049 - Hotel e Motel' },
  { value: '227', label: '0227 - House Cleaning' },
  {
    value: '152',
    label: '0152 - Indústrias (Impressão, Química, Têxtil, Vidro)',
  },
  { value: '27', label: '0027 - Instalação e Manutenção de Alarmes' },
  {
    value: '36',
    label: '0036 - Instalação e Reparos em Equipamentos de Produção',
  },
  { value: '218', label: '0218 - Insurance Sales, Underwriting, and Premiums' },
  { value: '38', label: '0038 - Joalherias' },
  { value: '26', label: '0026 - Jogos, Caça e Tiro Esportivo' },
  { value: '128', label: '0128 - Lavanderias' },
  { value: '60', label: '0060 - Lojas de Cama, Mesa e Banho' },
  { value: '59', label: '0059 - Lojas de Conveniências' },
  { value: '79', label: '0079 - Lojas de Móveis e Decorações' },
  { value: '89', label: '0089 - Lojas de Sapatos' },
  { value: '94', label: '0094 - Lojas de Vestuários' },
  { value: '132', label: '0132 - Lojas de Vinos e Bebidas Alcoólicas' },
  { value: '232', label: '0232 - Lojas Não Classificadas Anteriormente' },
  { value: '55', label: '0055 - Maquinas e Equipamentos' },
  { value: '243', label: '0243 - Manutenções de Jardins / Poda de Árvores' },
  { value: '77', label: '0077 - Marcenaria e Movelaria' },
  { value: '115', label: '0115 - Material Fotográfico' },
  { value: '103', label: '0103 - Materiais de Construção e Ferragens' },
  { value: '150', label: '0150 - Material e Suprimento Artístico' },
  { value: '55', label: '0055 - Mecânicas' },
  { value: '69', label: '0069 - Metais - Comércio' },
  { value: '6', label: '0006 - Mineração' },
  { value: '93', label: '0093 - Moda Feminina e Acessórios' },
  { value: '100', label: '0100 - Moda Masculina e Acessórios' },
  { value: '44', label: '0044 - Moto Peças e Acessórios' },
  { value: '93', label: '0093 - Motocicletas e Scooters' },
  { value: '155', label: '0155 - Moving and Storage' },
  { value: '248', label: '0248 - Naturopatas' },
  { value: '225', label: '0225 - News Dealers and Newsstands' },
  { value: '95', label: '0095 - Óticas' },
  { value: '4', label: '0004 - Outras Categorias' },
  { value: '84', label: '0084 - Papelaria / Livrarias' },
  { value: '247', label: '0247 - Pet Shops' },
  { value: '210', label: '0210 - Pharmacies' },
  { value: '118', label: '0118 - Photo Processing, Equipment, and Supplies' },
  { value: '224', label: '0224 - Photo Studio' },
  { value: '187', label: '0187 - Piscinas' },
  { value: '82', label: '0082 - Post Office' },
  { value: '74', label: '0074 - Printing and Publishing' },
  { value: '71', label: '0071 - Recreação e Lazer' },
  { value: '124', label: '0124 - Restaurantes e Bares' },
  { value: '164', label: '0164 - Serviços Agrícolas' },
  { value: '39', label: '0039 - Serviços Financeiros' },
  { value: '31', label: '0031 - Serviços Gerais' },
  { value: '205', label: '0205 - Serviços para Residências e Comércio' },
  { value: '182', label: '0182 - Sheds, Carports, and Portable Buildings' },
  { value: '31', label: '0031 - Supermercados' },
  { value: '66', label: '0066 - Tecnologia da Informação' },
  { value: '87', label: '0087 - Telefonia' },
  { value: '56', label: '0056 - Tintas e Vernizes' },
  { value: '129', label: '0129 - Tire Shops' },
  { value: '61', label: '0061 - Training and Development' },
  { value: '232', label: '0232 - Travel Agencies and Tour Operators' },
  { value: '231', label: '0231 - Trucking Companies' },
  { value: '231', label: '0231 - Varejo' },
  { value: '28', label: '0028 - Videolocadoras' },
  { value: '211', label: '0211 - Veterinários e Clínicas Veterinárias' },
  { value: '212', label: '0212 - Videos' },
  { value: '221', label: '0221 - Vidro / Cristais e Vidraçarias' },
  { value: '44', label: '0044 - Vigilância e Segurança' },
  { value: '135', label: '0135 - Vinhos e Bebidas Alcoólicas' },
  { value: '248', label: '0248 - Zooterapia' },
];

export const LINE_OF_BUSINESS_OPTIONS = [
  { value: '1', label: 'Acessórios' },
  { value: '29', label: 'Agência de Turismo' },
  { value: '2', label: 'Calçados' },
  { value: '4', label: 'Cama, Mesa e Banho' },
  { value: '3', label: 'Cosméticos' },
  { value: '5', label: 'Decoração' },
  { value: '26', label: 'Diversos' },
  { value: '6', label: 'Embalagem' },
  { value: '7', label: 'Fardamentos' },
  { value: '8', label: 'Fitness' },
  { value: '9', label: 'Jeans Masculino e Feminino' },
  { value: '10', label: 'Jeans Wear' },
  { value: '11', label: 'Moda Esportiva' },
  { value: '12', label: 'Moda Evangélica' },
  { value: '13', label: 'Moda Feminina' },
  { value: '14', label: 'Moda Gestante' },
  { value: '15', label: 'Moda Infantil' },
  { value: '16', label: 'Moda Infanto-Juvenil' },
  { value: '17', label: 'Moda Intima' },
  { value: '18', label: 'Moda Masculina' },
  { value: '19', label: 'Moda Plus Size' },
  { value: '20', label: 'Moda Praia' },
  { value: '21', label: 'Moda sem Gênero' },
  { value: '22', label: 'Moda Social' },
  { value: '28', label: 'Outros' },
  { value: '23', label: 'Recém-nascido' },
  { value: '24', label: 'Roupas de Dormir' },
  { value: '27', label: 'Seguros' },
  { value: '25', label: 'Surf Wear' },
];
