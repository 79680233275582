import { tv } from 'tailwind-variants';

const buttonIconVariants = tv({
  base: 'text-md',
  variants: {
    disabled: {
      true: 'text-md cursor-not-allowed !bg-neutral-300 !border-neutral-300 !text-neutral-600',
    },
    size: {
      medium: 'text-sm',
      large: 'text-heading-3',
    },
    ghost: {
      true: '!text-secondary',
    },
    link: {
      true: 'text-secondary hover:text-secondary-900',
    },
  },
});

export const ButtonIcon = ({ icon: Icon, className, ...props }) => {
  return (
    <Icon className={buttonIconVariants({ class: className, ...props })} />
  );
};
