import { create } from 'zustand';
import api from '@services/api';
import { PaymentMethod } from '@system/enums';
const INITIAL_STATE = {
  booking: {
    paymentMethod: PaymentMethod['Cartão de Crédito'],
  },
  travelers: [],
  traveler: null,
  modal: {
    createTravelerVisible: false,
    bookingConfirmed: false,
  },
  loading: false,
  flightDetail: null,
  seat: null,
  tablePrice: null,
  reservedSeats: [],
  //!TODO: remove in future
  opened: false,
  seatClass: '',
  specialSeat: false,
  dateValidate: false,
  hasEnoughAllotments: true,
  hasEnoughCredits: true,
  type: null,
  actualStatus: '',
  reservationInformations: {},
  routeInformations: {},
  totalQueue: 0,
  saidaEmergPrimC: [],
  saidaEmergPremium: [],
  saidaEmergExecutiva: [],
  saidaEmergEconomica: [],
  reservationIds: null,
  seatValue: null,
};
export const useBookings = create((set, get) => ({
  ...INITIAL_STATE,
  methods: {
    /**
     * Retrieves flight details for a given charter ID.
     *
     * @param {string} charterId - The ID of the charter.
     * @return {Promise<Object>} A promise that resolves to the flight details data.
     */
    getFlightDetails: async (charterId) => {
      set({ loading: true });
      const { data } = await api.get(`/charters/${charterId}`);
      set({ flightDetail: data, loading: false });
      return data;
    },
    createBooking: async (params) => {
      const { data } = await api.post('/bookings', params);
      get().methods.updateModal('bookingConfirmed', true);
      set({
        booking: data,
      });

      return data;
    },
    openOrCloseModal: (opened) => set({ opened }),
    setSaidas: ({
      saidaEmergPrimC,
      saidaEmergPremium,
      saidaEmergExecutiva,
      saidaEmergEconomica,
    }) => {
      if (saidaEmergPrimC) {
        set({ saidaEmergPrimC });
      }
      if (saidaEmergPremium) {
        set({ saidaEmergPremium });
      }
      if (saidaEmergExecutiva) {
        set({ saidaEmergExecutiva });
      }
      if (saidaEmergEconomica) {
        set({ saidaEmergEconomica });
      }
    },
    setReservationIds: (props) => {
      set({ reservationIds: props });
    },
    setDateValidate: (dateValidate) => set({ dateValidate }),

    setTotalQueue: (value) => set({ totalQueue: value }),
    setLoading: (loading) => {
      set({ loading });
    },
    addTravelers: (travelerInfo) => {
      set((prevState) => ({
        travelers: [...prevState.travelers, travelerInfo],
      }));
    },
    updateTravelerById: (id, newValues) => {
      set((prevState) => ({
        travelers: prevState.travelers.map((traveler) =>
          traveler.id === id ? { ...traveler, ...newValues } : traveler,
        ),
      }));
    },
    removeTravelers: (id) => {
      set((prevState) => ({
        travelers: prevState.travelers.filter((traveler) => traveler.id !== id),
      }));
    },
    updateModal: (name, status) => {
      set((prevState) => ({ modal: { ...prevState.modal, [name]: status } }));
    },
    setSeat: (seat) => {
      set((prevState) => ({ ...prevState, seat }));
    },
    updateTablePrice: (value) => {
      set({ tablePrice: value });
    },

    setTraveler: (traveler) => {
      set({ traveler });
    },
    updateStep: (step) => set({ step }),
    getReservedSeats: async (charterId) => {
      const { data } = await api.get(`/bookings/${charterId}/reserved-seats`);
      set({ reservedSeats: data });
    },
    getSummaryPrices: () => {
      const travelers = get().travelers;

      const adults = (travelers ?? []).filter(
        (i) => i.type.toLowerCase() === 'adt',
      );
      const childrens = (travelers ?? []).filter(
        (i) => i.type.toLowerCase() === 'chd',
      );
      const babies = (travelers ?? []).filter(
        (i) => i.type.toLowerCase() === 'inf',
      );
      const installmentSelected = get()?.installmentSelected ?? null;

      const totalCostAdults = adults
        .map((a) => Number(a.value))
        .reduce((prev, cur) => prev + cur, 0);
      const totalCostChildrens = childrens
        .map((a) => Number(a.value))
        .reduce((prev, cur) => prev + cur, 0);
      const totalCostBabies = babies
        .map((a) => Number(a.value))
        .reduce((prev, cur) => prev + cur, 0);

      const taxes = 0;
      let subtotal = totalCostAdults + totalCostChildrens + totalCostBabies;
      let installmentTax = 0;
      let newValue = 0;
      if (
        installmentSelected &&
        get().booking.paymentMethod === PaymentMethod['Cartão de Crédito']
      ) {
        const tax = Number(installmentSelected.interestRate) / 100;
        const result = Number(subtotal) / (1 - tax);
        const og = Math.pow(10, 2);
        newValue = Math.trunc(result * og) / og;
        installmentTax = newValue - subtotal;
      }
      subtotal += taxes;
      const total = subtotal + installmentTax;

      return {
        qtyAdults: adults.length,
        qtyChildrens: childrens.length,
        qtyBabies: babies.length,
        subtotal,
        total,
        totalCostAdults,
        totalCostBabies,
        totalCostChildrens,
        taxes,
        installmentTax,
      };
    },

    reset: () => {
      set(INITIAL_STATE);
    },
  },
}));
