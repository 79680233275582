import { Fragment, forwardRef, useId, useState } from 'react';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { useAirports } from '@stores/airports';
import {
  formatCityAirportState,
  formatScale,
  formatTime,
  getAirportNameByIata,
} from '@system/utils';

const DropdownListRoutes = forwardRef(
  ({ value, onChange, label, dataSearch, onSelectRoute, ...props }, ref) => {
    const { airports } = useAirports();

    const filtered =
      value?.length > 0
        ? dataSearch?.filter((rota) => {
            const formattedValue = value.toUpperCase().trim();
            return (
              rota.id_rota?.toString().toUpperCase().includes(formattedValue) ||
              rota.origem?.toUpperCase().includes(formattedValue) ||
              rota.cidade?.toUpperCase().includes(formattedValue) ||
              rota.uf?.toUpperCase().includes(formattedValue)
            );
          })
        : dataSearch;

    const [showContent, setShowContent] = useState(false);
    const handleFocus = () => {
      setTimeout(() => {
        setShowContent(true);
      }, 200);
    };
    const handleBlur = () => {
      setTimeout(() => {
        setShowContent(false);
      }, 200);
    };
    const id = useId();

    return (
      <div className="relative flex w-full flex-col gap-2">
        <Form.Label htmlFor={id}>{label}</Form.Label>
        <div className="relative flex flex-col">
          <Form.Group>
            <InputV2
              ref={ref}
              value={value}
              name={label}
              id={id}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={(e) => onChange(e.target.value)}
              {...props}
            />
          </Form.Group>

          {showContent && (
            <div className="absolute top-12 z-10 flex h-max max-h-[50vh] w-full flex-col overflow-y-scroll scroll-smooth rounded-md border border-solid border-neutral-400 bg-white drop-shadow-sm">
              {filtered.length == 0 && (
                <div className="cursor-pointer" onClick={() => {}}>
                  <div className="p-3">
                    <b>Rota não encontrada!</b>
                    <p className="text-secondary">
                      ❯ &nbsp;&nbsp;Cadastre Nova Rota
                    </p>
                  </div>
                </div>
              )}
              {filtered?.map((item, index) => (
                <Fragment key={index}>
                  <div
                    key={index}
                    onClick={() => onSelectRoute(item)}
                    className={`flex h-max w-full cursor-pointer flex-col justify-start rounded-t-md px-4 py-[14px] hover:bg-neutral-100`}
                  >
                    <div className="flex h-max w-full items-end justify-start">
                      <div className="flex h-full w-max flex-col items-start">
                        <div className="mb-[10px] flex items-center justify-start gap-2">
                          <img
                            width={31}
                            src={item?.logo}
                            alt={item?.nomeCia}
                          />
                          <span className="text-xs font-normal text-neutral-800">
                            {item?.operadopor &&
                              `Operado por ${item?.operadopor} `}
                            - Voo {item?.voo}
                          </span>
                        </div>

                        <div className="flex flex-col items-start gap-[5px]">
                          <div className="flex items-center gap-1">
                            <span className="text-heading-3 text-primary">
                              {item?.origem}
                            </span>
                            <span className="text-body text-neutral-800">
                              {formatTime(item?.saida).replace('h', '')}
                            </span>
                          </div>
                          <span className="text-small text-neutral-800">
                            {item?.nome}
                          </span>
                          <span className="text-small font-semibold text-neutral-800">
                            {item?.cidade}/{item?.uf}
                          </span>
                        </div>
                      </div>

                      <div className="m-0 ml-8 mr-7 flex h-24 w-[60px] flex-col items-center justify-center text-center text-xs font-normal text-neutral-700">
                        <span className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
                          {formatScale(item?.escala, item?.conexao)}
                          <hr className="mt-1 w-[60px] border-neutral-700" />
                        </span>
                        <div className="flex items-center justify-center gap-1">
                          {Array.from({
                            length: item?.escala + item?.conexao,
                          }).map((_, index) => (
                            <div
                              key={index}
                              className="z-10 -mt-[33px] h-2 w-2 rounded-full border border-solid !border-neutral-700 bg-white"
                            />
                          ))}
                        </div>
                      </div>

                      <div className="flex h-full w-max flex-col justify-end gap-5">
                        <div className="flex flex-col gap-[5px]">
                          <div className="flex items-center gap-1">
                            <span className="text-[14px] text-heading-3 font-semibold text-primary">
                              {item?.destino}
                            </span>
                            <span className="flex gap-1 text-sm uppercase text-neutral-800">
                              {formatTime(item?.chegada).replace('h', '')}
                            </span>
                          </div>
                          <span className="text-small text-neutral-800">
                            {getAirportNameByIata(item?.destino, airports)}
                          </span>
                          <span className="text-small font-semibold text-neutral-800">
                            {formatCityAirportState(airports, item?.destino)}
                          </span>
                        </div>
                      </div>
                      <div className="w-max"></div>
                    </div>
                  </div>
                  <div className="w-full px-4">
                    {index != filtered.length - 1 && <hr className="m-0" />}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  },
);

DropdownListRoutes.displayName = 'DropdownListRoutes';

export { DropdownListRoutes };
