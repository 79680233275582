import { useState, useEffect } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { PiPlusCircleFill } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumbs from '@components/breadcrumbs';
import api from '@services/api';
import Globals from '../components/Globals';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoFrota() {
  const [endpoint] = useState(Globals.endPoint);
  const navigate = useNavigate();
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [frotas, setFrotas] = useState({ data: [] });
  const [buscaFiltro, setBuscaFiltro] = useState('');

  useEffect(() => {
    listaUsers();
  }, []);

  function listaUsers() {
    setLoading(true);
    console.log('carregandos');
    api
      .get(endpoint + '/fleet')
      .then((response) => {
        if (response.data.count > 0) {
          setFrotas(response.data);
          setLoading(false);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Dados não encontrados!');
          setTimeout(() => setTypeAlert(''), 3000);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function editaaeronave(id) {
    navigate(PagePath.AereoFrotaEditar.replace(':id', id));
  }

  function newId() {
    navigate(PagePath.AereoFrotaCadastrar);
  }
  function listSearch() {
    setLoading(true);
    setTypeAlert('');
    const params = {
      filter: buscaFiltro,
    };

    console.log('param', params);
    api
      .get(endpoint + '/fleet', { params })
      .then((response) => {
        if (response.data.count > 0) {
          setFrotas(response.data);
          setLoading(false);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Dados não encontrados!');
          setTimeout(() => setTypeAlert(''), 3000);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }
  function changeBuscaFiltro(e) {
    setBuscaFiltro(e);
    setTypeAlert('');
    if (e.trim().length == 0) {
      listaUsers();
    }
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      listSearch();
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <BreadCrumbs
        paths={[
          { label: 'Aéreo', link: PagePath.Aereo },
          { label: 'Frota', link: PagePath.AereoFrota },
        ]}
      />

      <div className="row lineButtons">
        <div className="col ">
          <h1>Frota</h1>
        </div>
        <div className="col btnTable">
          <div className="buscaFiltro">
            <input
              type="text"
              className="inputSearch"
              placeholder="Procurar por"
              value={buscaFiltro}
              onChange={(e) => changeBuscaFiltro(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <FaMagnifyingGlass onClick={() => listSearch()} />
          </div>
          <div className="rightBtn">
            <div>{frotas.count}</div>
            <div>de</div>
            <div className="listTotal">{frotas.total}</div>
            <button
              type="button"
              className="btn btnNew"
              onClick={() => newId()}
            >
              Novo <PiPlusCircleFill />
            </button>
          </div>
        </div>
      </div>

      <div className="contentAll">
        <div className="row">
          <div className="col-12 table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <th scope="col">Aeronave</th>
                  <th scope="col">Companhia</th>
                  <th scope="col"></th>
                  <th scope="col" width="30%"></th>
                  <th scope="col" width="12%"></th>
                  <th scope="col" width="12%"></th>
                  <th scope="col" width="5%"></th>
                </tr>
              </thead>
              <tbody>
                {frotas?.data.map((data, index) => (
                  <tr key={index}>
                    <td onClick={() => editaaeronave(data.id)}>
                      {data.manufacture.aircraftName}
                    </td>
                    <td onClick={() => editaaeronave(data.id)}>
                      <img
                        className={data.airline.logo ? 'imgCia' : 'hide'}
                        src={data.airline.logo}
                      />{' '}
                      {data.airline.logo ? '' : data.airline.name}
                    </td>
                    <td onClick={() => editaaeronave(data.id)}></td>
                    <td onClick={() => editaaeronave(data.id)}></td>
                    <td>
                      <Link
                        to={PagePath.AereoFrotaAssentos.replace(':id', data.id)}
                      >
                        <button className="btnPeq">Assentos</button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={PagePath.AereoFrotaRotas.replace(':id', data.id)}
                      >
                        <button className="btnPeq">Rotas</button>
                      </Link>
                    </td>
                    <td onClick={() => editaaeronave(data.id)}>
                      <div className="btnAddValor" title="Alterar">
                        ✎
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
