import dayjs from 'dayjs';

export function calculateFlightDuration(
  timeOriginFlight,
  dateOriginFlight,
  timeDestinationFlight,
  dateDestinationFlight,
) {
  const ft = dayjs(`${dateOriginFlight} ${timeOriginFlight}`);
  const tt = dayjs(`${dateDestinationFlight} ${timeDestinationFlight}`);
  const minutes = tt.diff(ft, 'm');
  const totalHours = parseInt(minutes / 60);

  const totalMins = dayjs().minute(minutes).$m;

  return `${('0' + totalHours).slice(-2)}h ${('0' + totalMins).slice(-2)}m`;
}
