import { InputRadio } from '@components/input';
import { ButtonV2 } from '@components/new-components/button-v2';
import Tag from '@components/Tag';
import { PaymentMethod } from '@system/enums';

export default function PaymentMethodSelection({
  selectedMethod,
  onChange,
  goToVPCard,
  paymentMethodsAllowed,
}) {
  return (
    <div className="mt-4 flex flex-col">
      <div className="flex flex-col">
        <div className="flex shrink-0 flex-col items-start gap-x-3 border-0 border-b border-solid border-gray-300 pb-4">
          <InputRadio
            label="Cartão de crédito cliente"
            name="billing"
            value={PaymentMethod['Cartão de Crédito']}
            checked={selectedMethod === PaymentMethod['Cartão de Crédito']}
            onChange={onChange}
            disabled={!paymentMethodsAllowed.creditCard}
          />
          <small className="ml-8 text-small font-normal text-neutral-600">
            Via link de pagamentos
          </small>
        </div>
        <div className="flex shrink-0 flex-row items-center gap-x-3 border-0 border-b border-solid border-gray-300 py-4">
          <InputRadio
            label="Faturamento"
            name="billing"
            value={PaymentMethod.Faturamento}
            checked={selectedMethod === PaymentMethod.Faturamento}
            onChange={onChange}
            disabled={!paymentMethodsAllowed.invoicing}
          />
        </div>

        <div className="flex shrink-0 flex-row items-center gap-x-3 border-0 border-b border-solid border-gray-300 py-4">
          <div className="flex flex-row items-center justify-center gap-x-3">
            <InputRadio
              label="VP Card"
              name="billing"
              value={PaymentMethod.VPCard}
              disabled
            />
            <Tag value="Em breve" type="soon" />
            <ButtonV2 ghost onClick={goToVPCard} className="ml-4">
              <ButtonV2.Text>Solicite agora o seu</ButtonV2.Text>
            </ButtonV2>
          </div>
        </div>

        <div className="flex shrink-0 flex-row items-center gap-x-3 border-0 border-b border-solid border-gray-300 py-4">
          <InputRadio
            label="Pix"
            name="billing"
            value={PaymentMethod.Pix}
            disabled
          />
          <Tag value="Em breve" type="soon" />
        </div>
        <div className="flex shrink-0 flex-row items-center gap-x-3 pt-4">
          <InputRadio
            label="Pix Agendado"
            name="billing"
            value={PaymentMethod['Pix Agendado']}
            disabled
          />
          <Tag value="Em breve" type="soon" />
        </div>
      </div>
    </div>
  );
}
