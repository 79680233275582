import { format } from 'date-fns';
import { produce } from 'immer';

import { Modal } from '@components/modal';
import { useBookings } from '@stores/bookings';
import { PassengerType } from '@system/enums';

import { toaster } from '@system/utils/toaster';
import { FormPassenger } from './form-passenger';

export function ModalReservationCreatePassenger() {
  const {
    passengerType,
    tablePrice,
    traveler,
    travelers,
    seat,
    methods: { getSummaryPrices },
  } = useBookings();
  const { qtyAdults, qtyBabies } = getSummaryPrices();

  const handleClose = () => {
    useBookings.setState(
      produce((draft) => {
        draft.seat = null;
        draft.modal.createTravelerVisible = false;
        draft.passengerType = null;
        draft.traveler = null;
      }),
    );
  };

  const saveTraveler = (formData) => {
    let locator = '';

    let value = 0;
    if (passengerType === PassengerType.PassengerCommon) {
      value = tablePrice[seat.category][formData.type.toLowerCase()];
      locator = `AFB${format(new Date(), 'ddMMyyHHmmss')}`;
    } else if (passengerType === PassengerType.PassengerBaby && !traveler) {
      if (qtyBabies + 1 > qtyAdults) {
        return toaster(
          'warning',
          'É necessário cadastrar um adulto para cada bebê. 1 bebê por 1 adulto.',
        );
      }
    }
    if (!traveler) {
      //Validação de CPF no mesmo checkout.
      if (
        travelers?.some(
          (t) =>
            t.document.replace(/[._-]/g, '') ===
            formData.document.replace(/[._-]/g, ''),
        )
      ) {
        return toaster(
          'error',
          'O CPF já foi cadastrado nesta reserva e não pode ser inserido novamente.',
        );
      }
      useBookings.setState(
        produce((draft) => {
          draft.travelers.push({
            id: draft.travelers.length + 1,
            locator,
            document: formData.document,
            name: formData.name,
            lastName: formData.lastName,
            birthdate: formData.birthdate,
            gender: formData.gender,
            type: formData.type,
            phone: formData.phone,
            email: formData.email,
            seat: seat ? [seat.column, seat.row] : null,
            category: seat ? seat.category : null,
            note: formData.note,
            value,
          });
          draft.seat = null;
          draft.traveler = null;
          draft.modal.createTravelerVisible = false;
          draft.passengerType = null;
        }),
      );
    } else {
      useBookings.setState(
        produce((draft) => {
          let updatedTraveler = draft.travelers.find(
            (t) => t.id === traveler.id,
          );

          if (updatedTraveler) {
            Object.assign(updatedTraveler, formData);
          }
          draft.seat = null;
          draft.traveler = null;
          draft.modal.createTravelerVisible = false;
          draft.passengerType = null;
        }),
      );
    }
  };

  return (
    <Modal open={true} className=" w-11/12 max-w-7xl md:mx-auto  large:w-9/12 ">
      <Modal.Header
        title={`Dados do ${passengerType === PassengerType.PassengerBaby ? 'bebê (0 a 23 meses)' : 'assento'}`}
        handleClose={handleClose}
      />
      <FormPassenger onSubmit={saveTraveler} />
    </Modal>
  );
}
