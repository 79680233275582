export function formatCityAirport(route, airports) {
  const airport = airports.find((airport) => airport.iata === route);
  if (airport) {
    if (airport.city) {
      return `${airport.city}/${airport.state}`;
    }
    return `${airport.cidade}/${airport.uf}`;
  } else {
    return 'Aeroporto não encontrado';
  }
}
