import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';

import CreditAllocationInfo from '@components/credit-allocation-info';
import FlightDetails from '@components/flight-details';
import { FlightPriceDetail } from '@components/flight-price-detail';
import FlightRulesDetails from '@components/flight-rules-details';
import LoadingAnimation from '@components/LoadingAnimation';
import { ModalReservationCreatePassenger } from '@components/modal/modal-reservation-create-passenger';
import { ModalReservationConfirm } from '@components/modal/ModalReservationConfirm';

import { useBookings } from '@stores/bookings';
import { PaymentMethod } from '@system/enums';
import { PagePath } from '../pages-config';

const styles = {
  step: tv({
    base: 'whitespace-nowrap text-center text-body font-normal',
    variants: {
      active: {
        true: 'text-secondary',
        false: 'text-neutral-600',
      },
    },
  }),
  stepMarker: tv({
    base: 'flex size-[1.875rem] shrink-0 flex-col content-center items-center justify-center rounded-full text-center text-heading-3 text-white',
    variants: {
      active: {
        true: 'bg-secondary',
        false: 'bg-neutral-400',
      },
    },
  }),
};

export default function AereoReservaEtapas() {
  const [hasAnyRules, setHasAnyRules] = useState(false);
  const { state: params, pathname, state } = useLocation();
  const { loading, travelers, methods, booking, modal, flightDetail } =
    useBookings();

  const navigate = useNavigate();

  const steps = {
    [PagePath.AereoReservasCheckout]: 'checkout',
    [PagePath.AereoReservasPagamento]: 'payment',
    [PagePath.AereoReservasConfirmar]: 'confirmation',
  };

  const currentStep = steps[pathname] ?? 'checkout';

  function handleGoBack() {
    methods.reset();

    const hasQueryParams = state?.reservationsPageQueryParams;

    navigate({
      pathname: hasQueryParams
        ? PagePath.AereoReservasResultados
        : PagePath.AereoReservas,
      ...(hasQueryParams && { search: state.reservationsPageQueryParams }),
    });
  }

  const useAllotments = true;
  const useCredits =
    currentStep === 'checkout' ||
    booking?.paymentMethod === PaymentMethod.Faturamento;

  useEffect(() => {
    if (
      travelers?.length === 0 &&
      pathname !== PagePath.AereoReservasCheckout
    ) {
      navigate(PagePath.AereoReservasCheckout, {
        replace: true,
        state: params,
      });
    }

    methods.getFlightDetails(params.charterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flightDetail)
      setHasAnyRules(flightDetail.cancelRule || flightDetail.changeRule);
  }, [flightDetail]);

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="box-border flex w-full flex-row items-center justify-between bg-white p-4">
        <a
          href="#"
          onClick={handleGoBack}
          className="box-border w-auto whitespace-nowrap text-left"
        >
          Voltar
        </a>
        <div className="flex w-full items-center justify-center">
          <ul className="flex flex-row items-center justify-center gap-x-16">
            <li className="!mb-2 inline-flex flex-col items-center justify-center gap-y-2">
              <div
                className={styles.stepMarker({
                  active: currentStep === 'checkout',
                })}
              >
                1
              </div>
              <div
                className={styles.step({
                  active: currentStep === 'checkout',
                })}
              >
                Assentos e passageiros
              </div>
            </li>
            <li className="!mb-2 inline-flex flex-col items-center gap-y-2">
              <div
                className={styles.stepMarker({
                  active: currentStep === 'payment',
                })}
              >
                2
              </div>
              <div
                className={styles.step({
                  active: currentStep === 'payment',
                })}
              >
                Pagamento
              </div>
            </li>
            <li className="!mb-2 inline-flex flex-col items-center gap-y-2 ">
              <div
                className={styles.stepMarker({
                  active: currentStep === 'confirmation',
                })}
              >
                3
              </div>
              <div
                className={styles.step({
                  active: currentStep === 'confirmation',
                })}
              >
                Confirmação
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex w-full flex-row gap-4 p-4 sm:flex-nowrap">
        <Outlet />
        <div className="flex h-auto w-full max-w-[361px] shrink-0 grow-0 flex-col gap-y-3 self-start sm:inline-flex sm:w-4/12">
          <CreditAllocationInfo
            vertical
            showAllotments={useAllotments}
            showCredit={useCredits}
          />
          <FlightDetails />
          {hasAnyRules && <FlightRulesDetails />}
          {currentStep === 'checkout' && <FlightPriceDetail />}
        </div>
      </div>
      {modal.createTravelerVisible && <ModalReservationCreatePassenger />}
      <ModalReservationConfirm />
    </>
  );
}
