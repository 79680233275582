import { forwardRef, useId } from 'react';

export const InputRadio = forwardRef(function InputRadio(
  { label, className, ...props },
  ref,
) {
  const id = useId();

  return (
    <div className="flex items-center space-x-2.5">
      <input
        {...props}
        id={id}
        className={`size-5 accent-secondary ${className}`}
        ref={ref}
        type="radio"
      />
      {label && (
        <label
          className={`m-0 text-body   ${props.checked ? 'font-semibold text-neutral-800' : 'font-normal text-neutral-600'}`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  );
});
