import { BookingType } from '@system/enums';
export const convertBookingType = (type) => {
  const converter = {
    [BookingType.AR]: 'Aéreo',
    [BookingType.AF]: 'Aéreo fretamento',
    [BookingType.HL]: 'Hotelaria',
    [BookingType.RD]: 'Rodoviário',
    [BookingType.CZ]: 'Cruzeiro',
    [BookingType.PC]: 'Pacote',
    [BookingType.LV]: 'Locação de veículo',
  };
  return converter[type] ?? '';
};
