import { useEffect, useId, useRef, useState, forwardRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { tv } from 'tailwind-variants';

const selectField = tv({
  base: 'h-full w-full cursor-pointer !select-none !p-0 py-[14px] !pl-4 text-sm font-normal placeholder:text-sm placeholder:font-normal placeholder:italic placeholder:text-neutral-600',
});

const Select = forwardRef(
  (
    { options, onChange, value, label, placeholder, className, ...props },
    ref,
  ) => {
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const id = useId();

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className="relative w-full select-none">
        <div className="flex w-full flex-col gap-2">
          {label && (
            <label
              htmlFor={id}
              className="m-0 text-sm font-medium text-neutral-800"
            >
              {label}
            </label>
          )}
          <div
            ref={buttonRef}
            id={id}
            className="relative flex h-12 items-center justify-between rounded-md bg-white"
            type="button"
            onClick={toggleDropdown}
          >
            <input
              type="text"
              className={selectField({ className })}
              value={value}
              placeholder={placeholder}
              readOnly
              ref={ref}
              {...props}
            />
            <div className="absolute right-4 top-1/2 flex h-5 w-5 -translate-y-1/2 transform items-center justify-center text-[#343A3D]">
              <BiChevronDown size={22} />
            </div>
          </div>
        </div>

        {isOpen && (
          <div
            ref={dropdownRef}
            id="dropdownStates"
            className="absolute z-20 flex max-h-36 w-full flex-col divide-y divide-gray-100 overflow-x-auto rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-labelledby={id}
          >
            {options ? (
              options?.map((item) => (
                <div
                  key={item.value}
                  onClick={() => {
                    onChange(item.value);
                    setIsOpen(!isOpen);
                  }}
                  className="hover:gray-800 flex cursor-pointer items-center justify-between px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                  role="menuitem"
                >
                  {item.value}
                </div>
              ))
            ) : (
              <span>Empty</span>
            )}
          </div>
        )}
      </div>
    );
  },
);

Select.displayName = 'Select';

export { Select };
