import { useAuthentication } from '@stores/authentication';

export function useAccessControl() {
  const { session } = useAuthentication();

  function hasPermission(allowedRoles) {
    return session && allowedRoles.includes(session.role);
  }

  return { hasPermission };
}
