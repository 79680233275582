import { ButtonV2 } from '@components/new-components/button-v2';
import { PaymentMethod } from '@system/enums';

export default function CheckoutButtonGroup({
  onGoBack,
  onSubmit,
  selectedMethod,
}) {
  const buttonSubmitProps = {
    [PaymentMethod['Cartão de Crédito']]: {
      form: 'form-credit-card',
      type: 'submit',
    },
    [PaymentMethod.Faturamento]: {
      type: 'button',
      onClick: onSubmit.bind(this, null),
    },
  };

  return (
    <div className="mt-4 flex flex-row justify-between">
      <ButtonV2
        type="button"
        ghost
        size="large"
        className="w-2/12"
        onClick={onGoBack}
      >
        <ButtonV2.Text>Voltar</ButtonV2.Text>
      </ButtonV2>
      <ButtonV2
        size="large"
        className="w-2/12"
        disabled={!selectedMethod}
        {...buttonSubmitProps[selectedMethod]}
      >
        <ButtonV2.Text>Continuar</ButtonV2.Text>
      </ButtonV2>
    </div>
  );
}
