/* eslint-disable react/prop-types */
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { BsInfoCircleFill } from 'react-icons/bs';
import { GoAlertFill } from 'react-icons/go';

export const Alert = ({ type = 'success', children }) => {
  const typeSelector = {
    info: {
      icon: <BsInfoCircleFill className="text-info-500" size={24} />,
      bgColor: 'bg-info-100 !border-info-300 border border-solid',
      messageColor: '!text-neutral-800',
    },
    success: {
      icon: <AiFillCheckCircle className="text-success-500" size={24} />,
      bgColor: 'bg-success-100 !border-success-300 border border-solid',
      messageColor: '!text-neutral-800',
    },
    warning: {
      icon: <GoAlertFill className="text-warning-500" size={24} />,
      bgColor: 'bg-warning-100 !border-warning-300 border border-solid',
      messageColor: '!text-neutral-800',
    },
    error: {
      icon: <AiFillCloseCircle className="text-error-500" size={24} />,
      bgColor: 'bg-error-100 !border-error-300 border border-solid',
      messageColor: '!text-neutral-800',
    },
  };
  if (!typeSelector[type]) return;
  return (
    <div
      style={{}}
      className={`${typeSelector[type]?.bgColor} flex w-full content-center items-center gap-3 rounded-md border px-[20px] py-3 shadow-md `}
    >
      <span>{typeSelector[type]?.icon}</span>
      <div
        className={`!align-middle  text-small ${typeSelector[type]?.messageColor}`}
      >
        {children}
      </div>
    </div>
  );
};
