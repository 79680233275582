import api from '@services/api';
import { create } from 'zustand';

const INITIAL_STATE = {
  loading: false,
};

export const useExternalBookings = create((set) => ({
  ...INITIAL_STATE,
  methods: {
    getExternalBooking: async (reservationId) => {
      set({ loading: true });
      const { data } = await api.get(
        `/hotel-external-bookings/${reservationId}`,
      );
      set({ loading: false });
      return data;
    },
  },
}));
