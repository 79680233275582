import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { tv } from 'tailwind-variants';
import * as yup from 'yup';

import { PagePath } from '@/pages-config';
import Globals from '@components/Globals';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import api from '@services/api';

import './style.css';

const styles = {
  button: tv({
    base: 'font-semibold',
    variants: {
      loading: {
        true: 'hidden',
      },
    },
  }),
  loader: tv({
    base: [
      'relative top-[-20px] h-4 w-4 rounded-full text-[8px] text-white',
      'before:absolute before:left-[-3.5em] before:top-0 before:h-4 before:w-4 before:rounded-full before:content-[""]',
      'after:absolute after:left-[3.5em] after:top-0 after:h-4 after:w-4 after:rounded-full after:content-[""]',
    ],
    variants: {
      loading: {
        false: 'hidden',
        true: 'vgp-spinner',
      },
    },
  }),
  form: tv({
    variants: {
      hide: {
        true: 'hidden',
      },
    },
  }),
  message: tv({
    base: 'mt-5 text-center',
    variants: {
      show: {
        false: 'hidden',
      },
    },
  }),
  userNotFound: tv({
    base: 'mt-5 text-center',
    variants: {
      hide: {
        true: 'hidden',
      },
    },
  }),
};

const schemaValidation = yup.object().shape({
  password: yup.string().required('A senha é obrigatória'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas devem coincidir')
    .required('É necessário confirmar a senha'),
});

export default function NovaSenha() {
  const [idUser, setIdUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [updatePass, setupdatePass] = useState(false);

  const [queryParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });

  function message(msg) {
    return (
      <div className="max-w-[600px] rounded-[5px] bg-[#ec343a] p-5 text-white">
        <h4 className="text-xl text-white">{msg}</h4>
      </div>
    );
  }

  async function onSubmit(formData) {
    setLoading(true);

    try {
      const response = await api.post(
        Globals.endPoint + '/authentication/reset-password',
        {
          new_password: formData.password,
          user_id: idUser,
          token: queryParams.get('t'),
        },
      );

      if (response.status === 201) setupdatePass(true);
    } catch (error) {
      toast.custom(() => message(error?.message));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setIdUser(queryParams.get('uid'));
  }, [queryParams]);

  const hasUserId = !!idUser;

  return (
    <>
      <Form
        className={styles.form({ hide: !hasUserId || updatePass })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-5 mt-5">
          <h2>Redefinir Senha</h2>
          <p>Por favor, digite a nova senha.</p>
        </div>
        <div className="mt-5">
          <Form.Group>
            <Form.Label>Nova Senha</Form.Label>
            <InputV2
              type="password"
              placeholder="Nova senha"
              {...register('password')}
            />
            <Form.Message error={errors?.password} />
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>Confirme a nova Senha</Form.Label>
            <InputV2
              type="password"
              placeholder="Nova senha"
              {...register('confirmPassword')}
            />
            <Form.Message error={errors?.confirmPassword} />
          </Form.Group>
          <button type="submit" className="mt-[22px] h-[46px]">
            <span className={styles.button({ loading })}>Enviar</span>
            <span className={styles.loader({ loading })}></span>
          </button>
        </div>
      </Form>
      <div className={styles.message({ show: updatePass })}>
        <h2>Senha Alterada!</h2>
        <p>Senha alterada com sucesso.</p>
        <Link to={PagePath.Home} relative="path" className="inline-block">
          <ButtonV2 ghost>
            <ButtonV2.Text>Fazer login</ButtonV2.Text>
          </ButtonV2>
        </Link>
      </div>
      <div className={styles.userNotFound({ hide: hasUserId })}>
        Usuário não encontrado!
      </div>
    </>
  );
}
