export const FlightRulesTexts = {
  ALTERATION_WITH_DATE_AND_CUST:
    'Possivel alterar, sem custo adicional + diferença da tarifa, até $day-01$ dias antes da data de partida. Após $day-02$ dias, com custo de R$$cust$ + possível diferença da tarifa.',
  ALTERATION_WITH_CUST:
    'Possivel alterar, com custo de R$$cust$ + possível diferença da tarifa.',
  CANCELLATION_WITH_CUST:
    'Possível cancelar, com custo adicional de $cust$% da tarifa.',
  CANCELLATION_WITHOUT_CUST:
    'Possível cancelar, sem reembolso e custo adicional.',
  CANCELLATION_WITH_REFUND:
    'Possível cancelar, com $refund$% de reembolso da tarifa.',
  CANCELLATION_WITH_DATE_AND_REFUND:
    'Possível cancelar, com $refundBefore$% de reembolso da tarifa, até $day-01$ dias antes da data de partida. Após $day-02$ dias sera reembolsado somente $refundAfter$% da tarifa.',
};
