import { BookingStatus } from '@system/enums';
export const convertBookingStatus = (status) => {
  const converter = {
    [BookingStatus.Created]: 'Criado',
    [BookingStatus.Confirmed]: 'Confirmado',
    [BookingStatus.Paid]: 'Pago',
    [BookingStatus.Expired]: 'Expirado',
    [BookingStatus.Canceled]: 'Cancelado',
  };
  return converter[status] ?? '';
};
