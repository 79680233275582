function Switch({ onChange, checked, label }) {
  return (
    <div className="m-0 flex items-center gap-[10px]">
      <label className="relative m-0 flex h-5 w-10 cursor-pointer items-center p-0">
        <input
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={onChange}
        />
        <div
          className={`h-full w-full rounded-full ${checked ? 'bg-secondary' : 'bg-gray-300'}`}
        />
        <div
          className={`absolute ml-1 h-4 w-4 rounded-full bg-white shadow-md transition-transform duration-300 ${checked ? 'translate-x-full transform' : ''}`}
        />
      </label>
      <span className="m-0 !text-body text-neutral-800">{label}</span>
    </div>
  );
}

export { Switch };
