import Tag from '../components/Tag';
import './ConferenciaReserva.scss';
import { formatDate, formatCurrency } from '@system/utils';
import { convertStatusReservation } from '@system/converters/convertStatusReservation';
import { Suppliers } from '@system/enums';

export default function ConferenciaReserva({ reservation, selectedSupplier }) {
  const status = reservation.status
    ? convertStatusReservation(reservation.status, selectedSupplier)
    : {};

  return (
    <>
      <div className="my-0 mt-8 rounded-md bg-white p-5 shadow-sm">
        <h2 className="text-heading-2 text-primary">Detalhes da reserva</h2>
        <div className="row d-grid mx-2 my-5 gap-2.5 border-0 border-b border-solid border-neutral-300 py-5">
          <div className="col-5 flex flex-row items-center">
            <span className="mr-2 text-heading-3 text-primary">
              {' '}
              Código da reserva:{' '}
            </span>
            <span className="mr-5">{reservation?.id}</span>
            {Object.keys(status).length > 0 && (
              <Tag value={reservation.status} type={status.type} />
            )}
          </div>
        </div>
        <div className="row d-grid mx-2 gap-2.5 border-0 border-b border-solid border-neutral-300 pb-5">
          <span>
            <span className="mr-2 text-heading-3"> Data da criação: </span>
            {reservation?.dataCriacao}
          </span>
          <span>
            <span className="mr-2 text-heading-3"> Valor total: </span>
            {formatCurrency(reservation?.valorTotal)}
          </span>
          {reservation?.agencia && reservation?.agencia != '' && (
            <span>
              <span className="mr-2 text-heading-3"> Agência: </span>
              {reservation?.agencia}
            </span>
          )}
        </div>
        <div className="row d-grid mx-2 my-5 gap-2.5 border-0 border-b border-solid border-neutral-300">
          <h3 className="col text-heading-3 text-primary">
            {' '}
            Lista de passageiros:{' '}
          </h3>
          <table className="tblPassageirosReserva mx-2 text-start">
            <thead>
              <tr>
                <td>
                  <h3 className="text-heading-3 text-primary"> Nome </h3>
                </td>
                {!['ets', 'gol'].includes(selectedSupplier) && (
                  <td>
                    <h3 className="text-heading-3 text-primary"> CPF </h3>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              {reservation?.hospedes?.map((passengers) => (
                <tr key={passengers.id}>
                  <td key={passengers.nome}>{passengers.nome}</td>
                  {!['ets', 'gol'].includes(selectedSupplier) && (
                    <td key={passengers.cpf}>{passengers.cpf}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
