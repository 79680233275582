export const Tab = ({ active, children, onClick }) => (
  <div
    role="button"
    aria-selected={active}
    className="grow border-0 border-b-2 border-solid border-transparent py-4 text-center text-body font-semibold aria-selected:border-secondary aria-selected:text-secondary"
    onClick={onClick}
  >
    {children}
  </div>
);

export const TabContainer = ({ children }) => (
  <div className="flex border-0 border-b border-solid border-neutral-400 px-5">
    {children}
  </div>
);
