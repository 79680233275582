import { useEffect, useState } from 'react';
import { MdOutlineChair } from 'react-icons/md';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { TbAlertTriangle } from 'react-icons/tb';
import { tv } from 'tailwind-variants';
import Globals from '@components/Globals';
import { useAuthentication } from '@stores/authentication';
import { useCreditAllotment } from '@stores/credit-allotment';
import { formatCurrency } from '@system/utils';
import { clamp } from '@system/utils/clamp';
import { formatPercent } from '@system/utils/formatPercent';

const styles = {
  container: tv({
    base: 'mx-auto  bg-white p-5 items-center rounded-md relative   w-full',
    variants: {
      sticky: {
        true: '',
      },
    },
  }),
  header: tv({
    base: 'flex flex-row items-center justify-between cursor-pointer select-none  ',
    variants: {
      empty: {
        false: '!border-b border-solid border-0 border-neutral-300 !pb-3',
      },
    },
    defaultVariants: {
      empty: false,
    },
  }),
  containerItems: tv({
    base: 'list-none p-0 m-0 pt-3 items-start justify-start  content-start flex w-full',
    variants: {
      vertical: {
        true: 'flex-col flex-wrap gap-y-3',
        false: 'max-w-[1536px]',
      },
      empty: {
        true: 'hidden',
      },
    },
  }),

  item: tv({
    base: 'p-2 !border border-solid border-neutral-300 rounded-md flex flex-row items-center justify-start gap-3 w-full',
  }),
};
const ALLOTMENT_WARNING_THRESHOLD = 0.85;
const CREDIT_WARNING_THRESHOLD = 0.85;

function formatVencimento() {
  let now = new Date();
  let dia = ('' + now.getDate()).slice(-2);
  let mes = ('' + (now.getMonth() + 1)).slice(-2);
  let diaVencimento = useAuthentication.getState().session.agency.vencimento;
  if (Number(dia) > Number(diaVencimento)) {
    if (Number(mes) < 12) {
      mes = Number(mes) + 1;
    } else {
      mes = '1';
    }
  }

  if (Number(diaVencimento) < 10) {
    diaVencimento = '0' + diaVencimento;
  }
  if (Number(mes) < 10) {
    mes = '0' + mes;
  }
  return diaVencimento + '/' + mes;
}

const AllocationItem = ({ vertical = true }) => {
  const {
    allotment: { limit, used },
  } = useCreditAllotment();
  const percentOfUsedAllotment = clamp(used / limit, 0, 1);
  return (
    <li className={styles.item({ vertical })}>
      <div className=" self-start  rounded-md bg-secondary-100 p-4">
        <MdOutlineChair className="text-secondary" size={'1.25rem'} />
      </div>
      <div className="w-full">
        <span className="flex w-full flex-col whitespace-nowrap text-body font-semibold text-primary">
          Seu Allotment
        </span>
        <span className="text-small font-semibold text-neutral-700">
          {used}/{limit}{' '}
          <span className="font-normal">
            (válidos até {formatVencimento()})
          </span>
        </span>
        <span>
          <div className="progressbar">
            <div
              className="progressAllotment"
              style={{ width: formatPercent(percentOfUsedAllotment) }}
            ></div>
          </div>
        </span>
      </div>
    </li>
  );
};

const CreditItem = ({ vertical = true }) => {
  const {
    credit: { limit, used },
  } = useCreditAllotment();
  const showCreditWarning = false;
  const percentOfUsedAllotment = clamp(used / limit, 0, 1);
  return (
    <li className={styles.item({ vertical })}>
      <div className=" self-start  rounded-md bg-[#03A691]/10 p-4">
        <RiMoneyDollarCircleLine className="text-price" size={'1.375rem'} />
      </div>
      <div className="w-full">
        <span className="flex w-full flex-col whitespace-nowrap text-body font-semibold text-primary">
          Seus créditos para faturamento
        </span>
        <span className="text-small font-semibold text-neutral-700">
          {formatCurrency(used, true)} / {formatCurrency(limit, true)}
          {showCreditWarning && (
            <span>
              <TbAlertTriangle />
            </span>
          )}
        </span>
        <span>
          <div className="progressbar">
            <div
              className="progressAllotment"
              style={{ width: formatPercent(percentOfUsedAllotment) }}
            ></div>
          </div>
        </span>
      </div>
    </li>
  );
};

function CreditAllocationInfo({
  vertical = false,
  showCredit = true,
  showAllotments = true,
}) {
  const { session } = useAuthentication();
  const { methods } = useCreditAllotment();
  const [showBox, setShowBox] = useState(true);
  useEffect(() => {
    methods.getLimits();
  }, []);

  const toggleBox = () => {
    setShowBox((oldValue) => !oldValue);
  };

  return (
    <div className={styles.container({ sticky: true })}>
      <div
        className={styles.header({ empty: !showAllotments && !showCredit })}
        onClick={toggleBox}
      >
        <span className="text-heading-3 text-primary">
          {session?.agency?.nomeFantasia ?? ''}
        </span>
        {!showAllotments && !showCredit ? (
          false
        ) : showBox ? (
          <PiCaretUpBold className="text-primary" size={'1.125rem'} />
        ) : (
          <PiCaretDownBold className="text-primary" size={'1.125rem'} />
        )}
      </div>

      {showBox && (
        <ul
          className={styles.containerItems({
            vertical,
            empty: !showAllotments && !showCredit,
          })}
        >
          {showAllotments && <AllocationItem />}
          {showCredit && <CreditItem />}
        </ul>
      )}
    </div>
  );
}
export default CreditAllocationInfo;
