import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsSuitcase2Fill } from 'react-icons/bs';
import { IoAirplaneOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from '@components/button';
import {
  formatBaggage,
  formatCurrency,
  formatDate,
  formatTime,
  formatCityAirport,
  formatAirportScale,
  formatScale,
} from '@system/utils';
import { PagePath } from '../../pages-config';
import Tag from '../Tag';

function RouteCardDetailed(props) {
  const [contentToggle, setContentToggle] = useState(true);

  const { isReservationDetailsCard = false } = props;
  const className = props?.className;
  const cardDetailed = !isReservationDetailsCard
    ? 'shadow-md border border-solid border-[#EDEDED] p-5'
    : '';

  return (
    <div
      className={`flex w-full flex-col gap-5 rounded-md bg-white ${cardDetailed} ${className} ${props.onClick && 'cursor-pointer'}`}
      onClick={props.onClick}
    >
      <header
        className={`flex justify-between ${!isReservationDetailsCard ? 'border border-x-0 border-y-0 border-b border-solid border-[#EBEBEB] pb-4' : ''}`}
      >
        {' '}
        <div className="flex flex-col gap-[1px]">
          <span className="text-base font-semibold text-primary">
            {formatCityAirport(props.departureOrigin, props.airports)} -{' '}
            {formatCityAirport(props.departureDestination, props.airports)}
          </span>
          <span className="text-sm font-normal text-neutral-700">
            {formatDate(props.departureOriginDate)}{' '}
            {props.returnOrigin && `a ${formatDate(props.returnOriginDate)}`}
          </span>
        </div>
        {props.collapsible ? (
          <div
            className="h-8 w-8 cursor-pointer"
            onClick={() => setContentToggle(!contentToggle)}
          >
            {contentToggle ? (
              <BiChevronUp color="black" size={24} />
            ) : (
              <BiChevronDown color="black" size={24} />
            )}
          </div>
        ) : (
          <div className="flex gap-4">
            {props.showEditButton && (
              <Link
                to={PagePath.AereoSaidasEditar.replace(
                  ':id',
                  props.id_fretamento,
                )}
              >
                <Button label="Editar" variant="ghost" />
              </Link>
            )}

            {props.showPriceButton && (
              <Link
                to={PagePath.AereoSaidasPrecificar.replace(
                  ':id',
                  props.id_fretamento,
                )}
              >
                <Button label="Precificar" variant="ghost" />
              </Link>
            )}
          </div>
        )}
      </header>

      <div
        className={`flex max-sm:flex-col max-sm:items-start ${contentToggle ? ' ' : ' hidden'}`}
      >
        <div className="grid w-full grid-cols-2 gap-3 max-sm:flex max-sm:flex-col">
          <div className="flex w-full flex-col gap-[11px]">
            <Tag
              value={
                <div className="flex items-center gap-1">
                  <IoAirplaneOutline />{' '}
                  {props.returnOrigin ? 'Ida: ' : 'Trecho'}{' '}
                  {props.returnOrigin
                    ? moment(props.departureOriginDate).format('DD/MM/YY')
                    : ''}
                </div>
              }
            />

            <div className="flex flex-col gap-1">
              <div className="flex w-full items-center gap-2">
                <img
                  className="w-[31px]"
                  src={props.departureCompanyLogo}
                  alt=""
                />
                {props.departureOperator && (
                  <span className="text-small text-neutral-800">
                    Operado por {props.departureOperator} - Voo{' '}
                    {props.departureFlight}
                  </span>
                )}
              </div>
              <div className="flex flex-col">
                <div className="flex w-full gap-4">
                  <div className="flex flex-col">
                    <span className="text-base">
                      <b className="font-semibold text-primary">
                        {props.departureOrigin}
                      </b>{' '}
                      {formatTime(props.departureOriginTime)?.replace('h', '')}
                    </span>
                    <span className="text-xs font-normal text-gray-700">
                      {formatCityAirport(props.departureOrigin, props.airports)}
                    </span>
                  </div>
                  <div className="h-full">
                    <div className="">
                      <span className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
                        {formatScale(props.r1totalescala, props.r1totalconexao)}
                        <hr className="mt-1 w-[60px] border-neutral-700" />
                      </span>
                      <div className="flex items-center justify-center gap-1">
                        {Array.from({
                          length: formatAirportScale(
                            props.r1conexaoaeroporto,
                            props.r1escalaorigem,
                            props.r1escaladestino,
                            props.r1totalconexao,
                            props.r1totalescala,
                          ),
                        }).map((_, index) => (
                          <div
                            key={index}
                            className="z-0 -mt-[33px] h-2 w-2 rounded-full !border !border-solid !border-neutral-700 bg-white"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base text-primary">
                      <b className="font-semibold">
                        {props.departureDestination}
                      </b>{' '}
                      {formatTime(props.departureDestinationTime)?.replace(
                        'h',
                        '',
                      )}
                    </span>
                    <span className="text-xs font-normal text-gray-700">
                      {formatCityAirport(
                        props.departureDestination,
                        props.airports,
                      )}
                    </span>
                  </div>
                </div>
                <div className="mt-[11px] flex items-center gap-1 text-primary">
                  <BsSuitcase2Fill height={17} />
                  <span className="text-xs font-normal text-neutral-700">
                    Bagagem para despachar: {formatBaggage(props.baggage)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {props.returnOrigin && (
            <div className="flex w-max flex-col gap-[11px]">
              <Tag
                value={
                  <div className="flex items-center gap-1">
                    <IoAirplaneOutline className="rotate-180" /> Volta:{' '}
                    {moment(props.returnOriginDate).format('DD/MM/YY')}
                  </div>
                }
              />

              <div className="flex flex-col gap-1">
                <div className="flex w-full items-center gap-2">
                  <img className="w-8" src={props.returnCompanyLogo} alt="" />
                  {props.returnOperator && (
                    <span className="text-small text-neutral-800">
                      Operado por {props.returnOperator} - Voo{' '}
                      {props.returnFlight}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex w-full gap-4">
                    <div className="flex flex-col">
                      <span className="text-base text-primary">
                        <b className="font-semibold">{props.returnOrigin}</b>{' '}
                        {formatTime(props.returnOriginTime)?.replace('h', '')}
                      </span>
                      <span className="text-xs font-normal text-gray-700">
                        {formatCityAirport(props.returnOrigin, props.airports)}
                      </span>
                    </div>
                    <div className="h-full">
                      <div className="">
                        <span className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
                          {formatScale(
                            props?.r2escala,
                            props?.r2conexao,
                            props?.r2totalescala,
                            props?.r2totalconexao,
                          )}
                          <hr className="mt-1 w-[60px] border-neutral-700" />
                        </span>
                        <div className="flex items-center justify-center gap-1">
                          {Array.from({
                            length: formatAirportScale(
                              props.r2conexaoaeroporto,
                              props.r2escalaorigem,
                              props.r2escaladestino,
                              props.r2totalconexao,
                              props.r2totalescala,
                            ),
                          }).map((_, index) => (
                            <div
                              key={index}
                              className="z-0 -mt-[33px] h-2 w-2 rounded-full border border-solid !border-neutral-700 bg-white"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-base text-primary">
                        <b className="font-semibold">
                          {props.returnDestination}
                        </b>{' '}
                        {formatTime(props.returnDestinationTime)?.replace(
                          'h',
                          '',
                        )}
                      </span>
                      <span className="text-xs font-normal text-gray-700">
                        {formatCityAirport(
                          props.returnDestination,
                          props.airports,
                        )}
                      </span>
                    </div>
                  </div>
                  {props.returnBaggage && (
                    <div className="mt-2 flex items-center gap-1 text-primary">
                      <BsSuitcase2Fill height={17} />
                      <span className="text-xs font-normal text-neutral-700">
                        Bagagem para despachar:{' '}
                        {formatBaggage(props.returnBaggage)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {props.price && (
          <div className="flex min-w-44 flex-col items-start justify-center max-sm:mt-3 max-sm:w-full max-sm:items-start max-sm:border-t max-sm:pt-2">
            <span className="text-xl font-semibold text-[#03A691]">
              {formatCurrency(props.price)}
            </span>
            <span className="text-xs font-normal text-[#545962]">
              Preço final por adulto
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

RouteCardDetailed.propTypes = {
  baggage: PropTypes.string,
  price: PropTypes.string,
  airports: PropTypes.array,
  showPriceButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  collapsible: PropTypes.bool,

  // Departure
  departureCompanyLogo: PropTypes.string,
  departureOperator: PropTypes.string,
  departureFlight: PropTypes.string,
  departureOrigin: PropTypes.string,
  departureOriginTime: PropTypes.string,
  departureOriginDate: PropTypes.string,
  // Destination from departure flight
  departureDestination: PropTypes.string,
  departureDestinationTime: PropTypes.string,
  departureDestinationDate: PropTypes.string,

  // Return
  returnCompanyLogo: PropTypes.string,
  returnOperator: PropTypes.string,
  returnFlight: PropTypes.string,
  returnAirport: PropTypes.string,
  returnOrigin: PropTypes.string,
  returnOriginTime: PropTypes.string,
  returnOriginDate: PropTypes.string,
  // Destination from return flight
  returnDestination: PropTypes.string,
  returnDestinationTime: PropTypes.string,
  returnDestinationDate: PropTypes.string,
  returnBaggage: PropTypes.string,
};

export default RouteCardDetailed;
