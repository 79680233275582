import { AiOutlineClose } from 'react-icons/ai';
import {
  addMonths,
  eachMonthOfInterval,
  isEqual,
  startOfMonth,
} from 'date-fns';
import { formatToBRTz } from '@system/utils';

const MonthButton = ({ label, checked = false, onClick }) => (
  <span
    role="button"
    aria-checked={checked}
    className="mb-4 mr-4 inline-block rounded-2xl border border-solid border-neutral-300 px-4 py-1.5 text-body font-semibold hover:bg-secondary-100 hover:text-secondary aria-checked:border-secondary aria-checked:bg-secondary-100 aria-checked:text-secondary"
    onClick={onClick}
  >
    {label}
    {checked && <AiOutlineClose className="ml-2.5" />}
  </span>
);

export const YearCalendar = ({ selectedMonths, onSelect, onDeselect }) => {
  const months = eachMonthOfInterval({
    start: startOfMonth(new Date()),
    end: addMonths(startOfMonth(new Date()), 8),
  });

  function isChecked(month) {
    return selectedMonths.some((m) => isEqual(m, month));
  }

  function handleIntervalChange(month) {
    if (isChecked(month)) onDeselect(month);
    else onSelect(month);
  }

  return (
    <>
      {/**
       * @todo
       */}
      {/* <MonthButton label="Todos os meses" /> */}
      {months.map((month) => (
        <MonthButton
          key={month.toISOString()}
          label={formatToBRTz(month, 'MMMM/yyyy')}
          checked={isChecked(month)}
          onClick={() => handleIntervalChange(month)}
        />
      ))}
    </>
  );
};

export const YearCalendarContainer = ({ children }) => (
  <div className="px-5 pt-4">
    <p className="text-small font-semibold">
      Selecione o mês (ou meses) que deseja buscar.
    </p>
    {children}
  </div>
);
