import { useAuthentication } from '@stores/authentication';
import { Navigate } from 'react-router-dom';
import { PagePath } from '../../pages-config';

export function withAccessControl(
  component,
  allowedRoles,
  fallback = PagePath.Home,
) {
  return function AccessControlWrapper() {
    const { session } = useAuthentication();
    if (session && allowedRoles.includes(session.role)) {
      return component;
    } else {
      return <Navigate to={fallback} />;
    }
  };
}
