import { useBookings } from '@stores/bookings';
import { formatCurrency } from '@system/utils';

export const SummaryTravelersTotalizer = () => {
  const { methods } = useBookings();
  const {
    qtyAdults,
    qtyChildrens,
    qtyBabies,
    total,
    totalCostAdults,
    totalCostBabies,
    totalCostChildrens,
    // taxes,
    installmentTax,
  } = methods.getSummaryPrices();
  return (
    <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
      <ul className="list-none space-y-1.5 px-0">
        {qtyAdults > 0 && (
          <li className="text-body font-normal text-neutral-800">
            {qtyAdults} adultos:
            <span className="ml-0.5 text-body font-semibold text-price">
              {formatCurrency(totalCostAdults, true)}
            </span>
          </li>
        )}
        {qtyChildrens > 0 && (
          <li className="text-body font-normal text-neutral-800">
            {qtyChildrens} criança:
            <span className="ml-0.5 text-body font-semibold text-price">
              {formatCurrency(totalCostChildrens, true)}
            </span>
          </li>
        )}
        {qtyBabies > 0 && (
          <li className="text-body font-normal text-neutral-800">
            {qtyBabies} bebê:
            <span className="ml-0.5 text-body font-semibold text-price">
              {formatCurrency(totalCostBabies, true)}
            </span>
          </li>
        )}
        {/* <li className="pb-3 text-body font-normal text-neutral-800">
          Impostos, taxas e encargos:
          <span className="ml-0.5 text-body font-semibold text-price">
            {formatCurrency(taxes, true)}
          </span>
        </li> */}
        <li className="pb-3 text-body font-normal text-neutral-800">
          Acréscimos do juros do parcelamento:
          <span className="ml-0.5 text-body font-semibold text-price">
            {formatCurrency(installmentTax, true)}
          </span>
        </li>

        <li className="border-0 border-t border-solid border-neutral-600 pt-3 text-heading-3 font-semibold text-neutral-800">
          Valor total da reserva:
          <span className="ml-0.5  font-semibold text-price">
            {formatCurrency(total, true)}
          </span>
        </li>
      </ul>
    </div>
  );
};
