import { BiBus } from 'react-icons/bi';
import { BsSuitcase } from 'react-icons/bs';
import { FaSuitcaseRolling } from 'react-icons/fa';
import { GiCommercialAirplane } from 'react-icons/gi';
import { PiAirplaneInFlight } from 'react-icons/pi';
import Globals from '../components/Globals';
import { Menu } from '../components/menu/menu.jsx';
import { PagePath } from '../pages-config';
import { LuHotel } from 'react-icons/lu';

function Home() {
  return (
    <>
      <div className=" pb-4">
        <h4>Qual produto você deseja oferecer?!</h4>
      </div>
      <Menu>
        <Menu.Item
          active={true}
          to={PagePath.Aereo}
          title="Aéreo"
          icon={<GiCommercialAirplane className={'self-start'} />}
        />
          <Menu.Item
            to={PagePath.Hotel}
            title="Hotel"
            icon={<LuHotel className={'self-start'} />}
          />
        <Menu.Item
          to={PagePath.Rodoviario}
          title="Rodoviário"
          icon={<BiBus className={'self-start'} />}
        />
        <Menu.Item
          to={PagePath.PacoteFixo}
          title="Pacote"
          icon={<BsSuitcase className={'self-start'} />}
        />
        {Globals.userStatus === '9' && (
          <Menu.Item
            to={PagePath.Admin}
            title="Admin"
            subtitle=""
            icon={<FaSuitcaseRolling className={'self-start'} />}
          />
        )}
      </Menu>
    </>
  );
}

export default Home;
