import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

export function formatDate(date) {
  const dataCriada = new Date(date);
  const dataFormatada = dataCriada.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
  });

  if (date) {
    return dataFormatada;
  }
  return '-';
}

export function formatDateAereoReservas(date) {
  if (date) {
    const formattedDate = dayjs(date)
      .locale('pt-br')
      .format('ddd, DD MMM YYYY');

    return formattedDate[0].toUpperCase() + formattedDate.substring(1);
  }
  return '-';
}

export function formatDayMonth(date) {
  if (date) {
    const formattedDate = dayjs(date).locale('pt-br').format('DD MMM');
    return formattedDate;
  }
  return '-';
}
