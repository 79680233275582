import { Alert } from '@components/alert';
import { FineTotalizer } from '@components/checkout/fine';
import { useData } from '@stores/checkout-context';
import { formatCurrency } from '@system/utils';

export const CheckoutConfirmationCreditCardV2 = () => {
  const { data } = useData();

  return (
    <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
      <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
        Formas de pagamento
      </div>
      <div className="mt-2 pt-3">
        <span className="text-body font-semibold text-neutral-800">
          Cartão de Crédito cliente
        </span>
        <br />
        <div className="mt-3 text-body font-normal text-neutral-800">
          Bandeira do cartão de crédito:&nbsp;
          <span className="font-semibold capitalize">
            {data.brandName.toLowerCase()}
          </span>
        </div>
        <div className="mt-3 text-body font-normal text-neutral-800">
          Quantidade de parcelas:&nbsp;
          <span className="font-semibold">
            {data.installments} {data.installments > 1 ? 'parcelas' : 'parcela'}
            {' de '}
            {formatCurrency(data.installmentValue, true)}
            {' (juros de '}
            {data.tax}
            {')'}
          </span>
        </div>

        <div className="mt-3 text-body font-normal text-neutral-800">
          E-mail do pagador:&nbsp;
          <span className="font-semibold ">{data.payerEmail}</span>
        </div>
        <FineTotalizer />
      </div>

      <div className="mt-4">
        <Alert type="info">
          O link de pagamento será enviado para o e-mail informado. Fique atento
          ao prazo de expiração do link e informe o seu cliente.
        </Alert>
      </div>
    </div>
  );
};
