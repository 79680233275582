import ReactDOMServer from 'react-dom/server';
import { Tooltip as TooltipComponent } from 'react-tooltip';
import './tooltip.scss';

export const Tooltip = ({
  baseComponent,
  tooltipComponent,
  id,
  place = 'top',
}) => {
  return (
    <div>
      <span
        className="tooltip-base-component"
        data-tooltip-variant="light"
        data-tooltip-id={id}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          tooltipComponent,
        )}
      >
        {baseComponent}
      </span>
      <TooltipComponent
        anchorSelect=".tooltip-base-component"
        place={place}
        className="tooltip-component"
        border="1px solid lightgray"
        opacity={1.0}
        id={id}
      />
    </div>
  );
};
