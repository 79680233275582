import { format, toZonedTime } from 'date-fns-tz';
import * as Fns from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

const BRTimeZone = 'America/Sao_Paulo';

/**
 * @param {Date} date
 */
export const toBRTimeZone = (date) => {
  return toZonedTime(date, 'America/Sao_Paulo');
};

/**
 * @param {Date} date
 * @param {string} formatStr
 */
export const formatToBRTz = (date, formatStr) => {
  const text = format(date, formatStr, {
    timeZone: BRTimeZone,
    locale: ptBR,
  });
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatDateWithoutTZ = (date, formatStr) => {
  if (typeof date === Date) {
    return Fns.format(date.formatStr(), formatStr);
  }
  if (date.length <= 10) date = `${date}T00:00:00`;
  return Fns.format(date, formatStr);
};

const getDateMinus12Months = () => {
  const currentDate = new Date(); // Obtém a data atual
  return new Date(currentDate.setMonth(currentDate.getMonth() - 12)); // Subtrai 12 meses
};
