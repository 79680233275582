export default function Logo() {
  return (
    <svg
      width="100%"
      viewBox="0 0 422 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_203_3178)">
        <path
          d="M189.217 27.0853C194.086 31.833 196.525 37.7653 196.525 44.8633C196.525 51.9613 194.126 57.9594 189.317 62.6413C184.518 67.3232 178.192 69.6641 170.358 69.6641C162.525 69.6641 156.318 67.3232 151.549 62.6413C146.78 57.9594 144.39 52.0271 144.39 44.8633C144.39 37.6995 146.79 31.7484 151.599 27.0289C156.398 22.3094 162.654 19.9591 170.349 19.9591C178.043 19.9591 184.329 22.3376 189.208 27.0853H189.217ZM159.164 33.7227C156.338 36.7593 154.92 40.4729 154.92 44.8633C154.92 49.2537 156.318 52.9391 159.114 55.9099C161.911 58.8807 165.659 60.3662 170.358 60.3662C175.058 60.3662 178.826 58.8807 181.652 55.9099C184.478 52.9391 185.896 49.2537 185.896 44.8633C185.896 40.4729 184.428 36.8345 181.503 33.7697C178.578 30.7048 174.86 29.163 170.358 29.163C165.857 29.163 162 30.6766 159.164 33.7133V33.7227Z"
          fill="#162260"
        />
        <path
          d="M245.218 27.0853C250.087 31.833 252.526 37.7653 252.526 44.8633C252.526 51.9613 250.127 57.9594 245.318 62.6413C240.519 67.3232 234.193 69.6641 226.359 69.6641C218.526 69.6641 212.319 67.3232 207.55 62.6413C202.781 57.9594 200.391 52.0271 200.391 44.8633C200.391 37.6995 202.791 31.7484 207.6 27.0289C212.399 22.3094 218.655 19.9591 226.35 19.9591C234.044 19.9591 240.33 22.3376 245.209 27.0853H245.218ZM215.175 33.7227C212.349 36.7593 210.931 40.4729 210.931 44.8633C210.931 49.2537 212.329 52.9391 215.125 55.9099C217.921 58.8807 221.669 60.3662 226.369 60.3662C231.069 60.3662 234.837 58.8807 237.663 55.9099C240.489 52.9391 241.907 49.2537 241.907 44.8633C241.907 40.4729 240.439 36.8345 237.514 33.7697C234.579 30.7048 230.871 29.163 226.369 29.163C221.868 29.163 218.001 30.6766 215.175 33.7133V33.7227Z"
          fill="#162260"
        />
        <path
          d="M314.066 20.1566C310.01 20.1566 307.313 16.1986 308.949 12.6825C309.306 11.9116 309.713 11.1688 310.179 10.4637C311.954 7.74674 314.254 5.66904 317.08 4.21183C319.906 2.75462 322.91 1.69226 326.073 1.01536C329.246 0.338465 332.627 1.52588e-05 336.246 1.52588e-05C339.042 1.52588e-05 341.71 0.206845 344.268 0.629907C346.826 1.05297 349.305 1.77687 351.724 2.81102C354.143 3.84518 356.236 5.12376 358.01 6.63738C359.785 8.151 361.213 10.1065 362.304 12.5038C363.394 14.8918 363.94 17.6088 363.94 20.6454C363.94 34.3432 354.5 41.1874 335.632 41.1874C333.589 41.1874 331.646 41.3284 329.801 41.6199C327.957 41.9113 326.083 42.3908 324.179 43.0771C322.276 43.754 320.739 44.8257 319.579 46.2736C318.418 47.7214 317.843 49.4888 317.843 51.5571V58.5329H358.576C361.54 58.5329 363.94 60.8081 363.94 63.6191C363.94 66.4301 361.54 68.7052 358.576 68.7052H312.172C309.207 68.7052 306.808 66.4301 306.808 63.6191V51.5571C306.808 47.8718 307.621 44.6753 309.257 41.9677C310.893 39.2507 313.144 37.173 315.999 35.7158C318.865 34.2586 321.889 33.2151 325.091 32.5664C328.294 31.9177 331.765 31.598 335.513 31.598C337.357 31.598 339.171 31.4382 340.976 31.1091C342.78 30.7895 344.605 30.2724 346.439 29.5579C348.283 28.8434 349.781 27.7341 350.941 26.211C352.101 24.6974 352.676 22.9018 352.676 20.8335C352.676 13.5944 347.193 9.78684 336.226 9.40139C333.232 9.40139 330.466 9.72103 327.947 10.3697C325.429 11.0184 323.247 12.1748 321.403 13.8576C320.451 14.7226 319.737 15.7097 319.261 16.8003C318.379 18.8122 316.356 20.1566 314.056 20.1566H314.066Z"
          fill="url(#paint0_linear_203_3178)"
        />
        <path
          d="M133.206 27.0854C128.338 22.3377 122.051 19.9591 114.347 19.9591C108.309 19.9591 103.172 21.4163 98.9188 24.312V5.9605C98.9188 3.2059 96.5589 0.968369 93.6537 0.968369C90.7485 0.968369 88.3887 3.2059 88.3887 5.9605V44.8634C88.3887 52.0366 90.7684 57.9595 95.5476 62.6414C100.317 67.3232 106.583 69.6642 114.357 69.6642C122.131 69.6642 128.516 67.3232 133.315 62.6414C138.114 57.9595 140.524 52.0272 140.524 44.8634C140.524 37.6995 138.084 31.8331 133.216 27.0854H133.206ZM125.651 55.91C122.825 58.8808 119.057 60.3662 114.357 60.3662C109.657 60.3662 105.909 58.8808 103.113 55.91C100.317 52.9391 98.9188 49.2538 98.9188 44.8634C98.9188 40.4729 100.337 36.7594 103.163 33.7227C105.988 30.6861 109.717 29.1725 114.357 29.1725C118.997 29.1725 122.567 30.7049 125.502 33.7792C128.427 36.844 129.894 40.5481 129.894 44.8728C129.894 49.1974 128.476 52.9485 125.651 55.9194V55.91Z"
          fill="#162260"
        />
        <path
          d="M414.692 27.0854C409.824 22.3377 403.538 19.9591 395.833 19.9591C389.795 19.9591 384.659 21.4163 380.405 24.312V5.9605C380.405 3.2059 378.045 0.968369 375.14 0.968369C372.235 0.968369 369.875 3.2059 369.875 5.9605V44.8634C369.875 52.0366 372.255 57.9595 377.034 62.6414C381.803 67.3232 388.07 69.6642 395.843 69.6642C403.617 69.6642 410.002 67.3232 414.801 62.6414C419.6 57.9595 422.01 52.0272 422.01 44.8634C422.01 37.6995 419.571 31.8331 414.702 27.0854H414.692ZM407.127 55.91C404.301 58.8808 400.533 60.3662 395.833 60.3662C391.134 60.3662 387.386 58.8808 384.589 55.91C381.793 52.9391 380.395 49.2538 380.395 44.8634C380.395 40.4729 381.813 36.7594 384.639 33.7227C387.465 30.6861 391.193 29.1725 395.833 29.1725C400.474 29.1725 404.043 30.7049 406.978 33.7792C409.903 36.844 411.371 40.5481 411.371 44.8728C411.371 49.1974 409.953 52.9485 407.127 55.9194V55.91Z"
          fill="#162260"
        />
        <path
          d="M280.072 43.9138L297.652 26.6529C300.527 23.8325 298.415 19.1224 294.28 19.1224C293.011 19.1224 291.792 19.6113 290.909 20.4762L270.206 40.802V6.0075C270.206 3.2247 267.826 0.968369 264.891 0.968369C261.956 0.968369 259.577 3.2247 259.577 6.0075V63.6661C259.577 66.4489 261.956 68.7052 264.891 68.7052C267.826 68.7052 270.206 66.4489 270.206 63.6661V47.0163L290.909 67.342C291.792 68.207 293.011 68.6958 294.28 68.6958C298.415 68.6958 300.517 63.9857 297.652 61.1653L280.072 43.9044V43.9138Z"
          fill="#162260"
        />
        <path
          d="M34.4487 37.6995L74.2192 0L0.52832 18.7181L34.4487 37.6995Z"
          fill="#ED4736"
        />
        <path
          d="M54.4678 69.871L74.2192 0L34.4487 37.6995L54.4678 69.871Z"
          fill="#E41147"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_203_3178"
          x1="306.798"
          y1="34.3526"
          x2="363.93"
          y2="34.3526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED4736" />
          <stop offset="1" stopColor="#E41147" />
        </linearGradient>
        <clipPath id="clip0_203_3178">
          <rect
            width="421.472"
            height="69.871"
            fill="white"
            transform="translate(0.52832)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
