import { IoWarningOutline } from 'react-icons/io5';
import { tv } from 'tailwind-variants';

const style = tv({
  base: 'mt-2 text-neutral-700 text-xs font-normal flex items-center gap-1',
  variants: {
    error: {
      true: 'text-error-900',
      false: 'text-neutral-700',
    },
  },
});

export const FormMessage = ({ children, error, className }) => {
  if (!error && !children) return false;
  return (
    <span className={style({ error: Boolean(error), className })}>
      {error && <IoWarningOutline size={'0.75rem'} />}
      {children ? children : error?.message}
    </span>
  );
};

FormMessage.displayName = 'FormMessage';
